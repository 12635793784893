import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCustomers } from "./reduxSlices/slices/customerSlice";
import { setCollections } from "./reduxSlices/slices/collectionSlice";
import { setApprovedUsers } from "./reduxSlices/slices/approvedUsersSlice";
import { setSecurityLevel } from "./reduxSlices/slices/authSlice";
import { setExchangeRates } from "./reduxSlices/slices/exchangeRatesSlice";
import { setExpenses } from "./reduxSlices/slices/expenseSlice";
import { setInvestmentAccounts } from "./reduxSlices/slices/investmentAccountSlice";
import { setRatings } from "./reduxSlices/slices/ratingsSlice";
import Login from "./mainComponents/login/Login";
import Register from "./mainComponents/register/Register";
import Home from "./mainComponents/home/Home";
import ClientsTable from "./mainComponents/ClientsTable/ClientsTable";
import Projections from "./mainComponents/projections/Projections";
import Investments from "./mainComponents/Investments/Investments";
import CustomerInfo from "./mainComponents/CustomerInfo";
import Collections from "./mainComponents/Collections";
import Reports from "./mainComponents/Reports/Reports";
import ApprovedUsers from "./mainComponents/ApprovedUsers";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );

  const auth_user = useSelector(
    (state) => state.isAuthenticated.auth_user.value
  );

  useEffect(() => {
    //fetch all clients from api
    //set clients in state
    let fetchCustomersData = true;
    //start
    const fetchCustomers = async () => {
      const res = await fetch("/api/customer", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        const customers = data.customers;
        customers.forEach((customer) => {
          if (!customer.loan_number) {
            customer.loan_number = 1;
          }
        });
        dispatch(setCustomers(data.customers));
        fetchCustomersData = false;
      }
    };
    if (fetchCustomersData && auth_token !== null) fetchCustomers();
    //end
  }, [auth_token, dispatch, navigate]);

  useEffect(() => {
    //fetch all collections from api
    //set collections in redux state
    let fetchCollectionsData = true;
    //start
    const fetchCollections = async () => {
      const res = await fetch("/api/collection", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setCollections(data.collections));
        fetchCollectionsData = false;
      }
    };
    if (fetchCollectionsData && auth_token !== null) fetchCollections();
    //end
  }, [auth_token, dispatch, navigate]);

  useEffect(() => {
    //fetch all expenses from api
    //set expenses in redux state
    let fetchExpensesData = true;
    //start
    const fetchExpenses = async () => {
      const res = await fetch("/api/expense", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setExpenses(data.expenses));
        fetchExpensesData = false;
      }
    };
    if (fetchExpensesData && auth_token !== null) fetchExpenses();
    //end
  }, [auth_token, dispatch, navigate]);

  useEffect(() => {
    //fetch all approved users from api
    //set approved users in redux state
    let fetchApprovedUsersData = true;
    //start
    const fetchApprovedUsers = async () => {
      const res = await fetch("/api/approvedUser", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setApprovedUsers(data.approved_users));
        fetchApprovedUsersData = false;
      }
    };
    if (fetchApprovedUsersData && auth_token !== null) fetchApprovedUsers();
    //end
  }, [auth_token, dispatch, navigate]);

  useEffect(() => {
    //fetch security level from api
    //set security level in redux state

    let fetchSecurityLevelData = true;

    //start
    const fetchSecurityLevel = async () => {
      const res = await fetch("/api/approvedUser/" + auth_user, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setSecurityLevel(data.approved_user.security_level));
        fetchSecurityLevelData = false;
      }
    };

    if (fetchSecurityLevelData && auth_token !== null) fetchSecurityLevel();
    //end
  }, [auth_token, auth_user, dispatch, navigate]);

  useEffect(() => {
    let fetchExchangeRatesData = true;

    const fetchExchangeRates = async () => {
      const res = await fetch("/api/exchangeRates");

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        const info = data.exchangeRates[0];
        const newExChangeRates = {
          Date: info.Date,
          USD: info.USD,
          CAD: info.CAD,
          EUR: info.EUR,
          GBP: info.GBP,
          GHS: info.GHS,
        };
        dispatch(setExchangeRates(newExChangeRates));
        fetchExchangeRatesData = false;
      }
    };

    if (fetchExchangeRatesData && auth_token !== "null") fetchExchangeRates();
  }, [auth_token, dispatch]);

  useEffect(() => {
    //fetch all expenses from api
    //set expenses in redux state
    let fetchInvestmentsData = true;
    //start
    const fetchInvestmentAccounts = async () => {
      const res = await fetch("/api/investmentAccount", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setInvestmentAccounts(data.investmentAccounts));
        fetchInvestmentsData = false;
      }
    };
    if (fetchInvestmentsData && auth_token !== null) fetchInvestmentAccounts();
    //end
  }, [auth_token, dispatch, navigate]);

  useEffect(() => {
    // fetch all ratings from api
    // set ratings in redux state
    let fetchRatingsData = true;
    //start
    const fetchRatings = async () => {
      const res = await fetch("/api/ratings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        credentials: "include",
      });

      if (res.status === 200 || res.status === 304) {
        const data = await res.json();
        dispatch(setRatings(data.ratings));
        fetchRatingsData = false;
      }
    };
    if (fetchRatingsData && auth_token !== null) fetchRatings();
    //end
  }
  , [auth_token, dispatch, navigate]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/customerInfo" element={<CustomerInfo />}></Route>
        <Route exact path="/clients" element={<ClientsTable />}></Route>
        <Route exact path="/collections" element={<Collections />}></Route>
        <Route exact path="/reports" element={<Reports />}></Route>
        <Route exact path="/projections" element={<Projections />}></Route>
        <Route exact path="/investments" element={<Investments />}></Route>
        <Route exact path="/approvedUsers" element={<ApprovedUsers />}></Route>
      </Routes>
    </>
  );
}

export default App;
