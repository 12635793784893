import { configureStore } from "@reduxjs/toolkit";
import isAuthenticatedReducer from "../reduxSlices/slices/authSlice";
import clientReducer from "../reduxSlices/slices/clientSlice";
import scoreCardReducer from "../reduxSlices/slices/scoreCardSlice";
import paymentScheduleReducer from "../reduxSlices/slices/paymentScheduleSlice";
import disbursementScheduleReducer from "../reduxSlices/slices/disbursementScheduleSlice";
import applicationModeReducer from "../reduxSlices/slices/applicationModeSlice";
import customerReducer from "../reduxSlices/slices/customerSlice";
import collectionReducer from "../reduxSlices/slices/collectionSlice";
import approvedUsersReducer from "../reduxSlices/slices/approvedUsersSlice";
import exchangeRatesReducer from "../reduxSlices/slices/exchangeRatesSlice";
import expenseReducer from "../reduxSlices/slices/expenseSlice";
import investmentAccountsReducer from "../reduxSlices/slices/investmentAccountSlice";
import ratingsReducer from "../reduxSlices/slices/ratingsSlice";

const isDevelopment = process.env.NODE_ENV === 'development';

export default configureStore({
  reducer: {
    isAuthenticated: isAuthenticatedReducer,
    client: clientReducer,
    customer: customerReducer,
    scoreCard: scoreCardReducer,
    paymentSchedule: paymentScheduleReducer,
    collection: collectionReducer,
    disbursementSchedule: disbursementScheduleReducer,
    applicationMode: applicationModeReducer,
    approvedUsers: approvedUsersReducer,
    exchangeRates: exchangeRatesReducer,
    expense: expenseReducer,
    investmentAccount: investmentAccountsReducer,
    ratings: ratingsReducer,
  },
  devTools: isDevelopment, // This will enable devTools only in development mode
});
