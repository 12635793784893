import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "is_authenticated",
  initialState: {
    auth: { value: localStorage.getItem("loan_is_authenticated") || false },
    auth_token: { value: localStorage.getItem("loan_auth_token") || null },
    auth_user: { value: localStorage.getItem("loan_auth_user") || null },
    security_level: { value: 0 },
  },
  reducers: {
    setIsAuthenticated: (state, bool = false) => {
      if (state.auth.value !== bool.payload) {
        state.auth.value = bool.payload;
        localStorage.setItem("loan_is_authenticated", state.auth.value);
      }
    },
    setAuthToken: (state, token = null) => {
      state.auth_token.value = token.payload;
      localStorage.setItem("loan_auth_token", state.auth_token.value);
    },
    setAuthUser: (state, email = null) => {
      state.auth_user.value = email.payload;
      localStorage.setItem("loan_auth_user", state.auth_user.value);
    },
    setSecurityLevel: (state, level = 0) => {
      state.security_level.value = level.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsAuthenticated,
  setAuthToken,
  setAuthUser,
  setSecurityLevel,
} = authSlice.actions;

export default authSlice.reducer;
