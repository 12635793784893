
import { useState, useEffect } from "react"

/**
 * This hook smoothens the loading of an image
 * first time on a page or in a component
 * @returns [opacity, setFinishedLoading] = opacity changes from 0 to 100% in 3s and setFinishedLoading is a function that triggers opacity animation
 */
const useImageLoader = () => {
    const [isLoading, setIsLoading] = useState(true)  
    const [opacity, setOpacity] = useState(0)
    
    const setFinishedLoading = () => {
        setIsLoading(false)
    }

    useEffect(() => {
      if (!isLoading) {
        for (let i = 100; i >= 0; i--) {
          const op = i*0.01
          setTimeout(() => {
            setOpacity(op)
          },[2000 - 20*(100-i)])
        }      
      }
    },[isLoading])

    return [opacity, setFinishedLoading]
}

export default useImageLoader