import { createSlice } from '@reduxjs/toolkit'

export const scoreCardSlice = createSlice({
    name: 'scoreCard',
    initialState: {
        scoreCard: {
            value:
            {
                clientEmail: '',
                formNumber: '',
                dateOfApplication: '',
                overallRating: "",
                input: {},
            }
        },// { value: {clientEmail: '', formNumber: '', dateOfApplication: '', overallRating: "", input: {}, comments: {}}}
        scoreCards: { value: [] },
    },
    reducers: {
        setScoreCard: (state, scoreCard = {}) => {
            if (state.scoreCard.value !== scoreCard.payload) {
                state.scoreCard.value = scoreCard.payload
            }
        },
        setScoreCards: (state, scoreCards = []) => {
            if (state.scoreCards.value !== scoreCards.payload) {
                state.scoreCards.value = scoreCards.payload
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setScoreCard, setScoreCards } = scoreCardSlice.actions

export default scoreCardSlice.reducer