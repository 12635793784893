import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ratesObject } from "./investmentUtils";

const ReturnsTable = () => {
  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );

  const [amount, setAmount] = useState(10000); //
  const [period, setPeriod] = useState(1); // [1, 6, 12, 18, 24
  const [predictionValue, setPredictionValue] = useState(0);
  const [currency, setCurrency] = useState("GHS");
  const [showPredictionTable, setShowPredictionTable] = useState(false);

  return (
    <>
      <p className="w3-light-grey investmentParagraph fc jc aic">
        Below is the returns table for the investments. The returns are
        calculated based on the amount invested and the period of investment.{" "}
        <br />
        <br />
        The investor will receive the amount invested plus the returns at the
        end of the period of investment on a monthly basis (equal monthly
        installmants) for 6 months.
        <br />
        <br /> The minimum amount that can be invested is GHS10,000.
      </p>
      <div className="w3-responsive fc jc aic returns_table_div">
        <table
          id="investmentsTable"
          className="w3-table w3-striped w3-bordered investmentsTable w3-centered"
          tabIndex={0}
        >
          <thead>
            <tr className="w3-light-grey">
              <th scope="col">
                <span>Period (mths)</span>
              </th>
              <th scope="col">
                <span>Rate</span>
              </th>
            </tr>
          </thead>
          <tbody id="investmentsTableBody" style={{ textAlign: "center" }}>
            {Object.keys(ratesObject).map((key, index) => {
              return (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{ratesObject[key]}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="fc fdv js aic w3-padding-64">
        <div className="fc w3-padding-32">
          <span>Currency: &nbsp;</span>
          <select
            name="yesNo1"
            id="yesNo1"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option value="USD">usd</option>
            <option value="CAD">cad</option>
            <option value="EUR">eur</option>
            <option value="GBP">gbp</option>
            <option value="GHS">ghs</option>
          </select>
        </div>
        <h4>Predict a Return</h4>
        <div className="fc jc aic investment_prediction_div">
          <div className="fc fdv jc aic">
            <label htmlFor="amount">Amount (GHS)</label>
            <input
              type="number"
              name="amount"
              id="amount"
              placeholder="10000"
              min={10000}
              className="w3-input w3-border w3-round"
              onChange={(e) => {
                setAmount(e.target.value);
                setShowPredictionTable(false);
              }}
              value={amount}
            />
          </div>
          <div className="fc fdv jc aic">
            <select
              className="w3-select investmentsPredictionDropdown"
              name="investmentsPredictionDropdown"
              onChange={(e) => {
                setPeriod(e.target.value);
                setShowPredictionTable(false);
              }}
              style={{ border: "1px solid #ccc" }}
              value={period}
            >
              <option value="" disabled>
                Length of Investment
              </option>
              <option value="1">1 month</option>
              <option value="6">6 months</option>
              <option value="12">12 months</option>
              <option value="18">18 months</option>
              <option value="24">24 months</option>
            </select>
          </div>
          <div className="fc jc aic">
            <button
              className="w3-btn w3-blue w3-round"
              onClick={() => {
                setPredictionValue(amount * (1 + ratesObject[period] / 100));
                setShowPredictionTable(true);
              }}
            >
              Predict Return
            </button>
          </div>
        </div>
        <div className="fc jc aic showPredictionTableDiv">
          {showPredictionTable && (
            <div className="fc fdv jc aic w3-padding-64">
              <div className="fc fdv jc aic w3-text-blue">
                <span>
                  Initial Investment:
                  {(amount * exchangeRates[currency]).toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                  })}
                </span>
                <br />
                <span>
                  Total Return: GHS{" "}
                  {(predictionValue * exchangeRates[currency]).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: currency,
                    }
                  )}
                </span>
                <br />
              </div>
              <span
                className="w3-light-grey investmentParagraph fc jc aic"
                style={{ width: "100%" }}
              >
                After {period} month(s), you will receive{" "}
              </span>
              <table className="w3-table w3-striped w3-bordered investmentsTable w3-centered">
                <thead>
                  <tr className="w3-light-grey">
                    <th scope="col">
                      <span>Month #</span>
                    </th>
                    <th scope="col">
                      <span>Amount ({currency})</span>
                    </th>
                    <th scope="col">
                      <span>Interest ({currency})</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  id="investmentsTableBody"
                  style={{ textAlign: "center" }}
                >
                  {[0, 1, 2, 3, 4, 5].map((_, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {(
                            (predictionValue * exchangeRates[currency]) /
                            6
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: currency,
                          })}
                        </td>
                        <td>
                          {(
                            (((predictionValue - amount )* exchangeRates[currency]) /
                              6)).toLocaleString("en-US", {
                            style: "currency",
                            currency: currency,
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReturnsTable;
