import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: {
      value: {
        client_id: "", // Use Ghana Card Identification Number
        name: "",
        business_name: "",
        clientEmail: "",
        applications: [], // an array of formIds/formNumbers and application statuses, application: { formNumber: '', dateOfApplication: '', status: ''}
        phone: "",
        currentApplication: {},
      },
    }, // {client_id, name: '', business_name: '', email: '', phone: '', date_of_application, form_number, application_status: ''}
    clients: { value: [] },
  },
  reducers: {
    setClient: (state, client = {}) => {
      if (state.client.value !== client.payload) {
        state.client.value = client.payload;
        // localStorage.setItem('client', state.client.value)
      }
    },
    setClients: (state, clients = []) => {
      if (
        JSON.stringify(state.clients.value) !== JSON.stringify(clients.payload)
      ) {
        state.clients.value = clients.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClient, setClients } = clientSlice.actions;

export default clientSlice.reducer;
