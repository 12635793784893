/**
 *
 * @param {*} id : string id of an element e.g. "#homeDiv2RowCard"
 */
export const smoothScroll = (id) => {
  document.querySelector(id).scrollIntoView({
    behavior: "smooth",
  });
};

export const deepCopy = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

export const parseBoolean = (boolString) => {
  let str = boolString.toString();
  return str.trim().toLowerCase() === "true" ? true : false;
};

export const toggleVisibility = (id) => {
  const elem = document.getElementById(id);
  if (elem) {
    elem.style.display =
      elem.style.display === "none" ? "inline-block" : "none";
  }
};

export const setTokenAndUser = (
  token,
  email,
  setAuthToken,
  setAuthUser,
  dispatch
) => {
  dispatch(setAuthToken(token));
  dispatch(setAuthUser(email));
  return;
};

export const getNewTokens = async (auth_token) => {
  const response = await fetch("/api/getNewTokens", {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `Bearer x23gt35 ${auth_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response; // parses JSON response into native JavaScript objects
};

// function that creates an array from 0 to specifiec number
export const fillArrayTilNum = (num) => {
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(i);
  }
  return arr;
};

/**
 *
 * @param {*} array
 * @returns: sum of the array
 */
export const getArrayTotal = (array) => {
  return array.reduce((acc, curr) => acc + curr.amount, 0);
};

/**
 *
 * @param {*} fromDate
 * @param {*} toDate
 * @returns True if fromDate is less than or equal to toDate
 */
export const validDateRange = (fromDate, toDate) => {
  if (fromDate === "" || toDate === "") {
    alert("Please select a date range");
    return false;
  }

  if (fromDate > toDate) {
    alert("From date cannot be greater than to date");
    return false;
  }

  return true;
};

/**
 *
 * @param {*} date
 * @returns: returns date in the form "January 20, 2024"
 */
export const displayDateInWords = (date) => {
  const dateObj = new Date(date);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "Africa/Accra",
  };
  return dateObj.toLocaleDateString("en-US", options);
};
