import React, { useState } from "react";
import ReturnsTable from "./ReturnsTable";
import InvestmentAccounts from "./InvestmentAccounts";

import Navbar from "../../smallComponents/navBar/Navbar";

const Investments = () => {
  const [screen, setScreen] = useState("");

  return (
    <>
      <Navbar />
      <div className="fc fdv jc aic">
        <span className="investmentsPageWelcomeText">
          Welcome To the Investments Page
        </span>
        <div className="fc fdv jc aic">
          <select
            className="w3-select investmentsSelect"
            name="investment"
            onChange={(e) => {
              setScreen(e.target.value);
            }}
            defaultValue={""}
          >
            <option value="" disabled>
              Choose your option
            </option>
            <option value="returns_table">Table of Returns</option>
            <option value="investments">Investments</option>
          </select>
        </div>
        {screen === "returns_table" && <ReturnsTable />}
        {screen === "investments" && <InvestmentAccounts />}
      </div>
    </>
  );
};

export default Investments;
