import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import {
  getTotalCollectionsFromTo,
  getDailyCollections,
} from "./reportsUtilFunctions";
import { displayDateInWords } from "../../utils/utils";

const dailyOptions = {
  chart: {
    title: "Total Daily Collections",
    subtitle: "Values are in GHS",
  },
};

const CollectionsSummary = ({ currentReport }) => {
  const collections = useSelector(
    (state) => state.collection.collections.value
  );
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState("");
  const [totalCollections, setTotalCollections] = useState("");
  const [totalDailyCollectionsArray, setTotalDailyCollectionsArray] = useState(
    []
  );

  useEffect(() => {
    setTotalCollections("");
  }, [fromDate, toDate]);

  return (
    <>
      <div className="fc reportCollectionsFilterDates">
        <div className="fc fdv aic reportCollectionsFilterInputs">
          <span className="reportSpan">From</span>
          <input
            type="date"
            className="w3-input reportInput"
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            value={fromDate}
          />
        </div>
        <div className="fc fdv aic reportCollectionsFilterInputs">
          <span className="reportSpan">To</span>
          <input
            type="date"
            className="w3-input reportInput"
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            value={toDate}
          />
        </div>
      </div>
      <div className="fc jc aic">
        <button
          className="w3-button w3-green reportInput reportCollectionsFilterButton w3-round-xxlarge w3-margin"
          onClick={() => {
            setTotalCollections(
              getTotalCollectionsFromTo(collections, fromDate, toDate)
            );
            setTotalDailyCollectionsArray(
              getDailyCollections(collections, fromDate, toDate, currentReport)
            );
          }}
        >
          Generate
        </button>
      </div>
      {fromDate !== "" && toDate !== "" && totalCollections !== "" && (
        <>
          <div className="fc fdv aic collectionsSummaryCard w3-card">
            <span>Summary for:</span>
            <span className="w3-light-grey w3-text-green w3-padding">
              {displayDateInWords(fromDate)} &nbsp;to&nbsp; {displayDateInWords(toDate)}{" "}
            </span>
            <div
              className="fc fdv jc afs collectionsSummaryCardInfoDiv"
              style={{ width: "100%" }}
            >
              <span style={{ marginTop: "20px" }} className="fc jc aic">
                Total Collections: &nbsp;
                <span className="w3-text-green">
                  {totalCollections === ""
                    ? ""
                    : totalCollections.toLocaleString("en-US", {
                        style: "currency",
                        currency: "GHS",
                      })}
                </span>
              </span>
            </div>
          </div>
          <div className="fc jc aic collectionsSummaryDailyChartDiv w3-card w3-white">
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={totalDailyCollectionsArray}
              options={dailyOptions}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CollectionsSummary;
