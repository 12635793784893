import { createSlice } from "@reduxjs/toolkit";

export const approvedUsersSlice = createSlice({
  name: "approvedUsers",
  initialState: {
    approvedUsers: { value: [] },
  },
  reducers: {
    setApprovedUsers: (state, approvedUsers = []) => {
      if (
        JSON.stringify(state.approvedUsers.value) !==
        JSON.stringify(approvedUsers.payload)
      ) {
        state.approvedUsers.value = approvedUsers.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setApprovedUsers } = approvedUsersSlice.actions;

export default approvedUsersSlice.reducer;
