import { createSlice } from "@reduxjs/toolkit";

export const disbursementScheduleSlice = createSlice({
  name: "disbursementSchedule",
  initialState: {
    disbursementSchedule: {
      value: {
        clientEmail: "",
        formNumber: "",
        payments: [], //{date: '', amount: 0, balance: 0}
      },
    }, //
    disbursementSchedules: { value: [] },
  },
  reducers: {
    setdisbursementSchedule: (state, disbursementSchedule = {}) => {
      if (state.disbursementSchedule.value !== disbursementSchedule.payload) {
        state.disbursementSchedule.value = disbursementSchedule.payload;
      }
    },
    setDisbursementSchedules: (state, disbursementSchedules = []) => {
      if (
        JSON.stringify(state.disbursementSchedules.value) !==
        JSON.stringify(disbursementSchedules.payload)
      ) {
        state.disbursementSchedules.value = disbursementSchedules.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setdisbursementSchedule, setDisbursementSchedules } =
  disbursementScheduleSlice.actions;

export default disbursementScheduleSlice.reducer;
