import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCustomer } from "../../reduxSlices/slices/customerSlice";
import { setApplicationMode } from "../../reduxSlices/slices/applicationModeSlice";
import Navbar from "../../smallComponents/navBar/Navbar";
import {
  getTotalCustomerCollections,
  getTotalOutstandingBalance,
  getTotalPortfolioValue,
  getTotalLoansDisbursed,
  getSortedCustomers,
  getTotalCollections,
  getTotalFeesCollected,
  getTotalExpensesBasedOnCategory,
  getTotalInvestments,
  getTotalInvestmentFromDate,
  getTotalExpenses,
  getTotalExpensesFromDateMinusBankTransactions,
  getTotalDepositsFrom,
  getTotalWithdrawalsFrom,
} from "./ClientsTableUtils";
import { displayDateInWords } from "../../utils/utils";

import {
  getTotalCollectionsFrom,
  getTotalFeesFrom,
  getTotalDisbursementsFrom,
} from "../Reports/reportsUtilFunctions";

const ClientsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers.value);
  const collections = useSelector(
    (state) => state.collection.collections.value
  );

  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );

  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );
  const expenses = useSelector((state) => state.expense.expenses.value);
  const investment_accounts = useSelector(
    (state) => state.investmentAccount.investmentAccounts.value
  );

  const [outStandingBalance, setOutStandingBalance] = useState(0);
  const [loanPortfolioValue, setLoanPortfolioValue] = useState(0);
  const [loansDisbursed, setLoansDisbursed] = useState(0);
  const [sortedCustomers, setSortedCustomers] = useState([]); // [1]
  const [sortingParameter, setSortingParameter] = useState("last_name"); // can be " last_name, first_name, loan_number, loan_id, loan_amount_ghs, loan_date, loan_duration_wks, amount_remaining_ghs"
  const [sortDirection, setSortDirection] = useState("ascending"); // can be "ascending" or "descending"
  const [currency, setCurrency] = useState("GHS");
  const [bank_deposit, setBankDeposit] = useState(
    expenses
      .filter((expense) => expense.category === "bank_deposit")
      .reduce((acc, expense) => acc + expense.amount, 0)
  );
  const [bank_withdrawal, setBankWithdrawal] = useState(
    expenses
      .filter((expense) => expense.category === "bank_withdrawal")
      .reduce((acc, expense) => acc + expense.amount, 0)
  );

  const INITIAL_CAPITAL = 60000;

  useEffect(() => {
    if (parseInt(security_level) < 2) {
      navigate("/");
    }
  }, [navigate, security_level]);

  useEffect(() => {
    setOutStandingBalance(getTotalOutstandingBalance(customers, collections));
    setLoanPortfolioValue(getTotalPortfolioValue(customers));
    setLoansDisbursed(getTotalLoansDisbursed(customers));
  }, [collections, customers]);

  useEffect(() => {
    setSortedCustomers(
      getSortedCustomers(customers, sortDirection, sortingParameter)
    );
  }, [customers, sortingParameter, sortDirection]);

  useEffect(() => {
    setBankDeposit(
      expenses
        .filter((expense) => expense.category === "bank_deposit")
        .reduce((acc, expense) => acc + expense.amount, 0)
    );
    setBankWithdrawal(
      expenses
        .filter((expense) => expense.category === "bank_withdrawal")
        .reduce((acc, expense) => acc + expense.amount, 0)
    );
  }, [expenses]);

  const cashAtHand =
    getTotalInvestmentFromDate(investment_accounts, "2024-01-27") +
    getTotalCollectionsFrom(
      collections,
      "2024-01-27"
    ) +
    getTotalFeesFrom(
      customers,
      "2024-01-27"
    ) -
    getTotalDisbursementsFrom(
      customers,
      "2024-01-27"
    ) -
    getTotalExpensesFromDateMinusBankTransactions(expenses, "2024-01-27");

  return (
    <>
      <Navbar />
      <div className="fc fdv jc aic clientsDiv w3-light-grey">
        <h1 id="clientsHeaderText">Clients</h1>
        <div className="fc w3-padding-32">
          <span>Currency: &nbsp;</span>
          <select
            name="yesNo1"
            id="yesNo1"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option value="USD">usd</option>
            <option value="CAD">cad</option>
            <option value="EUR">eur</option>
            <option value="GBP">gbp</option>
            <option value="GHS">ghs</option>
          </select>
        </div>
        <div id="clientsTableWrapperDiv" className="clientsTableWrapperDiv">
          <div className="fc jfe aic" style={{ width: "80%" }}>
            <span>
              <b>Currency Chosen:</b> &nbsp;
            </span>
            <span className="exchangeRateValue">{currency}</span>
          </div>
          <table id="clientsTable" className="clientsTable" tabIndex={0}>
            <thead>
              <tr>
                <th scope="col">
                  <span>#</span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "last_name") {
                        setSortingParameter("last_name");
                      }
                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    Last Name
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "first_name") {
                        setSortingParameter("first_name");
                      }

                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    First Name
                  </span>
                </th>
                <th scope="col">
                  <span>Phone #</span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "loan_number") {
                        setSortingParameter("loan_number");
                      }

                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    Loan #
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "loan_id") {
                        setSortingParameter("loan_id");
                      }

                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    loan Id
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "loan_amount_ghs") {
                        setSortingParameter("loan_amount_ghs");
                      }
                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    loan_amount
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "loan_date") {
                        setSortingParameter("loan_date");
                      }

                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    loan_start_date
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="w3-button clientsTableHeaderButton"
                    onClick={() => {
                      if (sortingParameter !== "loan_duration_wks") {
                        setSortingParameter("loan_duration_wks");
                      }

                      setSortDirection(
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending"
                      );
                    }}
                  >
                    loan_tenure (wks)
                  </span>
                </th>
                <th scope="col">
                  <span>interest_rate (%)</span>
                </th>
                <th scope="col">
                  <span> Collections (GHS)</span>
                </th>
                <th scope="col">
                  <span>loan_balance (GHS)</span>
                </th>
                <th scope="col">
                  <span>P+I (GHS)</span>
                </th>
                <th scope="col">
                  <span style={{ width: "200px" }}>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody id="clientsTableBody" style={{ textAlign: "center" }}>
              {sortedCustomers.map((customer, index) => (
                <tr key={index} className="w3-card w3-white w3-text-black">
                  <td>
                    <span>{index + 1}</span>
                  </td>
                  <td className="w3-text-blue">
                    <span>{customer.last_name}</span>
                  </td>
                  <td className="w3-text-blue">
                    <span>{customer.first_name}</span>
                  </td>
                  <td>
                    <span>{customer.phone}</span>
                  </td>
                  <td>
                    <span>{customer.loan_number || 1}</span>
                  </td>
                  <td>
                    <span>{customer.loan_id}</span>
                  </td>
                  <td>
                    <span>
                      {customer.loan_amount_ghs *
                        exchangeRates[currency].toFixed(2)}
                    </span>
                  </td>
                  <td>
                    <span>{displayDateInWords(customer.loan_date)}</span>
                  </td>
                  <td>
                    <span>{customer.loan_duration_wks}</span>
                  </td>
                  <td>
                    <span>{customer.loan_interest_rate}</span>
                  </td>
                  <td>
                    <span>
                      {(
                        getTotalCustomerCollections(
                          collections,
                          customer.loan_id
                        ) * exchangeRates[currency]
                      ).toFixed(2)}
                    </span>
                  </td>
                  <td>
                    <span>
                      {(
                        (customer.amount_remaining_ghs -
                          getTotalCustomerCollections(
                            collections,
                            customer.loan_id
                          )) *
                        exchangeRates[currency]
                      ).toFixed(2)}
                    </span>
                  </td>
                  <td>
                    <span>
                      {(
                        customer.loan_amount_ghs *
                        (1 + customer.loan_interest_rate / 100) *
                        exchangeRates[currency]
                      ).toFixed(2)}
                    </span>
                  </td>
                  <td id="clientsTableButtonsColumn">
                    {customer.amount_remaining_ghs -
                      getTotalCustomerCollections(
                        collections,
                        customer.loan_id
                      ) >
                    0 ? (
                      <div className="fc jc aic">
                        <button
                          className="w3-button clientsTableEditButton w3-blue"
                          onClick={() => {
                            dispatch(setApplicationMode({ mode: "edit_mode" }));
                            dispatch(setCustomer(customer));
                            navigate("/customerInfo");
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="w3-button clientsTableNewButton w3-yellow"
                          onClick={() => {
                            dispatch(
                              setCustomer({
                                last_name: customer.last_name,
                                first_name: customer.first_name,
                                ghana_card_id: customer.ghana_card_id,
                                phone: customer.phone,
                                loan_number: customer.loan_number
                                  ? customer.loan_number + 1
                                  : 2,
                                loan_id: "",
                                loan_date: "",
                                loan_amount_ghs: 0,
                                loan_duration_wks: 0,
                                registration_fee_ghs: 0,
                                insurance_fee_ghs: 0,
                                loan_interest_rate: 0,
                                amount_remaining_ghs: 0,
                              })
                            );
                            dispatch(
                              setApplicationMode({ mode: "new_application" })
                            );
                            navigate("/customerInfo");
                          }}
                        >
                          New
                        </button>
                      </div>
                    ) : (
                      <div className="clientsTableDoneDiv w3-green fc jc aic w3-padding">
                        DONE
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="fc fdv jc aic clientsPageBottomRows">
          <div className="fc jc aic w3-light-grey clientsPageBottomRowsHeading">
            <h5>Investments</h5>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Total Initial Capital</div>
            <div className="w3-pink clientsPageBottomRows3">
              {(INITIAL_CAPITAL * exchangeRates[currency]).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: currency,
                }
              )}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Total Investment</div>
            <div className="w3-pink clientsPageBottomRows3">
              {(
                getTotalInvestments(investment_accounts) *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-card w3-grey clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">
              Total Investment plus Capital
            </div>
            <div className="w3-pink clientsPageBottomRows3">
              {(
                (INITIAL_CAPITAL + getTotalInvestments(investment_accounts)) *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Total Loans Disbursed</div>
            <div className="w3-yellow clientsPageBottomRows3">
              {(loansDisbursed * exchangeRates[currency]).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: currency,
                }
              )}
            </div>
          </div>
          <div className="fc jc aic w3-light-grey clientsPageBottomRowsHeading">
            <h5>Accounts Receivable</h5>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Expected Collections</div>
            <div className="w3-light-blue clientsPageBottomRows3">
              {(loanPortfolioValue * exchangeRates[currency]).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: currency,
                }
              )}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">
              {" "}
              Historical Collections
            </div>
            <div className="w3-light-blue clientsPageBottomRows3">
              {(
                getTotalCollections(collections) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">
              Historical Fees Collected
            </div>
            <div className="w3-light-blue clientsPageBottomRows3">
              {(
                getTotalFeesCollected(customers) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc fdv jc aic w3-light-grey w3-card total_cash_row">
            <div
              className="fc fdv jsb aic w3-card w3-white cash_at_hand_rows"
              style={{ marginBottom: "10px" }}
            >
              <div className="clientsPageBottomRows2">Total Investments</div>
              <div
                className="w3-blue clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalInvestmentFromDate(
                    investment_accounts,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Collections</div>
              <div
                className="w3-blue clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalCollectionsFrom(
                    collections,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Fees</div>
              <div
                className="w3-blue clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalFeesFrom(
                    customers,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Bank Deposits</div>
              <div
                className="w3-black clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalDepositsFrom(
                    expenses,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Withdrawals</div>
              <div
                className="w3-yellow clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalWithdrawalsFrom(
                    expenses,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Disbursements</div>
              <div
                className="w3-red clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  getTotalDisbursementsFrom(
                    customers,
                    "2024-01-27"
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>

              <div className="clientsPageBottomRows2">Total Expenses</div>
              <div
                className="w3-red clientsPageBottomRows3"
                style={{ marginBottom: "20px" }}
              >
                {(
                  (getTotalExpensesFromDateMinusBankTransactions(expenses, "2024-01-27"))* exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>             
              <div className="clientsPageBottomRows2">Cash At Hand</div>
              <div className="w3-green clientsPageBottomRows3">
                {(
                  (cashAtHand + bank_withdrawal - getTotalDepositsFrom(
                    expenses,
                    "2024-01-27"
                  ) ) *
                  exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>
            </div>
            <div className="fc jsb aic w3-card w3-white clientsPageBankDepositRow">
              <div className="clientsPageBottomRows2">Cash in Bank</div>
              <div className="w3-green clientsPageBottomRows3">
                {(
                  (bank_deposit - bank_withdrawal) *
                  exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </div>
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-grey clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Remaining Collections</div>
            <div className="w3-light-blue clientsPageBottomRows3">
              {(
                (loanPortfolioValue - cashAtHand) *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-light-grey">
            <h5>Expenses</h5>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Miscellaneous Expenses</div>
            <div className="w3-purple clientsPageBottomRows3">
              {(
                getTotalExpensesBasedOnCategory(
                  expenses,
                  "miscellaneous_Expenses"
                ) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Salary Expenses</div>
            <div className="w3-purple clientsPageBottomRows3">
              {(
                getTotalExpensesBasedOnCategory(
                  expenses,
                  "salaries_and_wages"
                ) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Rental Expenses</div>
            <div className="w3-purple clientsPageBottomRows3">
              {(
                getTotalExpensesBasedOnCategory(expenses, "rent_or_lease") *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Utilities</div>
            <div className="w3-purple clientsPageBottomRows3">
              {" "}
              {(
                getTotalExpensesBasedOnCategory(expenses, "utilities") *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Professional Fees</div>
            <div className="w3-purple clientsPageBottomRows3">
              {(
                getTotalExpensesBasedOnCategory(expenses, "professional_fees") *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-grey clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Total Expenses</div>
            <div className="w3-purple clientsPageBottomRows3">
              {(
                getTotalExpenses(expenses) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows0">
            <div className="clientsPageBottomRows2">
              Gross Portfolio Value (Includes Fees)
            </div>
            <div className="w3-yellow clientsPageBottomRows3">
              {(
                (loanPortfolioValue + getTotalFeesCollected(customers)) *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
          <div className="fc jsb aic w3-card w3-white clientsPageBottomRows1">
            <div className="clientsPageBottomRows2">Net Portfolio Value</div>
            <div className="w3-yellow clientsPageBottomRows3">
              {(
                (loanPortfolioValue +
                  getTotalFeesCollected(customers) -
                  getTotalExpenses(expenses)) *
                exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientsTable;
