import React from 'react';

const errorBoundaryStyle = {
    width: '100%',
    height: '100vh',
    backgroundColor: '#303030',
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
        this.setState({hasError: true ,error: error, errorInfo: errorInfo});
    console.log("------------------->: ",error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="fc jc aic error-boundary" style={errorBoundaryStyle}>
            <h1>Something went wrong.</h1>
            <div className='fc jc aic'>
                <span>{this.state.error.message}</span>
            </div>
            <h2>Try to refresh the page.</h2>
        </div>
      )
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

