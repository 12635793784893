import { createSlice } from "@reduxjs/toolkit";

export const expenseSlice = createSlice({
  name: "expense",
  initialState: {
    expense: {
      value: {
        id: "",
        category: "",
        amount: 0,
        date: "",
        description: "",
        createdBy: "",
      },
    },
    expenses: { value: [] }, // an array of expense objects
  },
  reducers: {
    setExpense: (state, expense = {}) => {
      if (state.expense.value !== expense.payload) {
        state.expense.value = expense.payload || {};
      }
    },
    setExpenses: (state, expenses = []) => {
      if (
        JSON.stringify(state.expenses.value) !==
        JSON.stringify(expenses.payload)
      ) {
        state.expenses.value = expenses.payload || [];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setExpense, setExpenses } = expenseSlice.actions;

export default expenseSlice.reducer;
