import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import {
  getPortfolioValue,
  getTotalDailyLoansValue,
} from "./reportsUtilFunctions";
import { displayDateInWords } from "../../utils/utils";

const dailyLoanPortfolioValueOptions = {
  chart: {
    title: "Total Daily Loan Portfolio Value",
    subtitle: "Values are in GHS",
  },
};

const PortfolioValue = ({ currentReport }) => {
  const customers = useSelector((state) => state.customer.customers.value);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState("");
  const [loanPortfolioValue, setLoanPortfolioValue] = useState("");
  const [loanPortfolioValueDailyArray, setLoanPortfolioValueDailyArray] =
    useState([]);

  useEffect(() => {
    setLoanPortfolioValue("");
  }, [fromDate, toDate]);

  return (
    <>
      <div className="fc reportCollectionsFilterDates">
        <div className="fc fdv aic reportCollectionsFilterInputs">
          <span className="reportSpan">From</span>
          <input
            type="date"
            className="w3-input reportInput"
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            value={fromDate}
          />
        </div>
        <div className="fc fdv aic reportCollectionsFilterInputs">
          <span className="reportSpan">To</span>
          <input
            type="date"
            className="w3-input reportInput"
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            value={toDate}
          />
        </div>
      </div>
      <div className="fc jc aic">
        <button
          className="w3-button w3-green reportInput reportCollectionsFilterButton w3-round-xxlarge w3-margin"
          onClick={() => {
            setLoanPortfolioValue(getPortfolioValue(customers, toDate));
            setLoanPortfolioValueDailyArray(
              getTotalDailyLoansValue(
                customers,
                fromDate,
                toDate,
                currentReport
              )
            );
          }}
        >
          Generate
        </button>
      </div>
      {fromDate !== "" && toDate !== "" && loanPortfolioValue !== "" && (
        <>
          <div className="fc fdv aic collectionsSummaryCard w3-card">
            <span>Summary For:</span>
            <span className="w3-light-grey w3-text-green w3-padding">
              {displayDateInWords(fromDate)} &nbsp;to&nbsp;{" "}
              {displayDateInWords(toDate)}{" "}
            </span>
            <div
              className="fc fdv jc afs collectionsSummaryCardInfoDiv"
              style={{ width: "100%" }}
            >
              <span style={{ marginTop: "20px" }} className="fc jc aic">
                Total Portfolio Value: &nbsp;
                <span className="w3-text-green">
                  {loanPortfolioValue === ""
                    ? "0"
                    : loanPortfolioValue.toLocaleString("en-US", {
                        style: "currency",
                        currency: "GHS",
                      })}
                </span>
              </span>
            </div>
          </div>
          <div className="fc jc aic collectionsSummaryDailyChartDiv w3-card w3-white">
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={loanPortfolioValueDailyArray}
              options={dailyLoanPortfolioValueOptions}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PortfolioValue;
