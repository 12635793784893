import { createSlice } from "@reduxjs/toolkit";

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    collection: {
      value: {
        loan_id: "",
        payments: [],
      },
    },
    collections: { value: [] }, // an array of date and amount
  },
  reducers: {
    setCollection: (state, collection = {}) => {
      if (state.collection.value !== collection.payload) {
        state.collection.value = collection.payload;
      }
    },
    setCollections: (state, collections = []) => {
      if (
        JSON.stringify(state.collections.value) !==
        JSON.stringify(collections.payload)
      ) {
        state.collections.value = collections.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCollection, setCollections } = collectionSlice.actions;

export default collectionSlice.reducer;
