import { createSlice } from "@reduxjs/toolkit";

export const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState: {
    exchangeRates: {
      value: {
        Date: Date.now(),
        USD: 0,
        CAD: 0,
        EUR: 0,
        GBP: 0,
        GHS: 0,
      },
    },
  },
  reducers: {
    setExchangeRates: (state, rates = {}) => {
      if (state.exchangeRates.value !== rates.payload) {
        state.exchangeRates.value = rates.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setExchangeRates } = exchangeRatesSlice.actions;

export default exchangeRatesSlice.reducer;
