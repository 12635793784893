import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../smallComponents/navBar/Navbar";
import { setApprovedUsers } from "../reduxSlices/slices/approvedUsersSlice";

const ApprovedUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );

  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );

  const approvedUsers = useSelector(
    (state) => state.approvedUsers.approvedUsers.value
  );

  const [addUserMode, setAddUserMode] = useState(false);
  const [newAuthorizedUser, setNewAuthorizedUser] = useState({
    email: "",
    security_level: "0",
  });

  useEffect(() => {
    if (parseInt(security_level) !== 3) {
      navigate("/");
    }
  }, [navigate, security_level]);

  const createNewAuthorizedUser = async () => {
    const response = await fetch("/api/approvedUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
      body: JSON.stringify(newAuthorizedUser),
    });
    if (response.status === 403 || response.status === 401) {
      alert("error saving new approved user: try logging in again");
      navigate("/login");
    } else if (response.status === 200) {
      alert("new approved user saved");
      dispatch(setApprovedUsers([...approvedUsers, newAuthorizedUser]));
    }
    setNewAuthorizedUser({ email: "", security_level: "0" });
  };

  return (
    <>
      <Navbar />
      <div className="fc fdv aic approvedUsersDiv">
        <h1>Approved Users</h1>

        <table className="w3-table-all approvedUsersTable">
          <thead>
            <tr className="w3-light-grey">
              <th>Email</th>
              <th>Security Level</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {approvedUsers.map((user) => {
              return (
                <tr key={user.email}>
                  <td>{user.email}</td>
                  <td>{user.security_level}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {addUserMode && (
          <div className="fc jc aic addUserDiv">
            <table className="w3-table addUserTable">
              <thead>
                <tr className="w3-yellow">
                  <th>Email</th>
                  <th>Security Level</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      autoComplete="off" 
                      className="w3-input"
                      type="text"
                      name="email"
                      id="email"
                      value={newAuthorizedUser.email}
                      onChange={(e) => {
                        setNewAuthorizedUser({
                          ...newAuthorizedUser,
                          email: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <select
                      className="w3-select"
                      name="securityLevel"
                      id="securityLevel"
                      value={newAuthorizedUser.security_level}
                      onChange={(e) => {
                        setNewAuthorizedUser({
                          ...newAuthorizedUser,
                          security_level: e.target.value,
                        });
                      }}
                    >
                      <option value="0">{""}</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </td>
                  <td>
                    <button
                      className="w3-button w3-green w3-hover-red w3-round-xxlarge"
                      onClick={() => {
                        setAddUserMode(false);
                        createNewAuthorizedUser();
                      }}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="fc jc aic" style={{ marginTop: "25px" }}>
          <button
            className="w3-button w3-blue w3-hover-red w3-round-xxlarge"
            onClick={() => {
              setAddUserMode(true);
            }}
          >
            Add User
          </button>
        </div>
      </div>
    </>
  );
};

export default ApprovedUsers;
