import { createSlice } from "@reduxjs/toolkit";

export const ratingsSlice = createSlice({
  name: "ratings",
  initialState: {
    ratings: { value: [] },// each ranking object has a {ghana_card_id, rating, comment}
  },
  reducers: {
    setRatings: (state, ratings = []) => {
      if (
        JSON.stringify(state.ratings.value) !==
        JSON.stringify(ratings.payload)
      ) {
        state.ratings.value = ratings.payload || [];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRatings } = ratingsSlice.actions;

export default ratingsSlice.reducer;
