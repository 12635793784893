import { validDateRange } from "../../utils/utils";

export const getCollectionForLoanIdOnDate = (collections, loan_id, date) => {
  if (typeof collections !== "object" || collections.length === 0) {
    return 0;
  }
  const collection = collections.find(
    (collection) => collection.loan_id === loan_id
  );

  if (!collection) {
    return 0;
  }

  const payments = collection.payments.filter(
    (payment) => payment.date === date
  );

  const totalPayment = payments.reduce((acc, curr) => acc + curr.amount, 0);
  return totalPayment;
};

export const collectionsOnSetDate = (collections, date) => {
  return collections.filter(
    (collection) =>
      getCollectionForLoanIdOnDate(collections, collection.loan_id, date) > 0
  );
};

/**
 *
 * @param {*} customers
 * @param {*} date
 * @returns: array of loans disbursed on a particular date i.e customers who received loans on a particular date
 */
export const loansDisbursedOnSetDate = (customers, date) => {
  return customers.filter(
    (customer) => customer.loan_date.slice(0, 10) === date
  );
};

export const getCustomer = (customers, loanId) => {
  return customers.find((customer) => customer.loan_id === loanId);
};

export const getLoanAmount = (customers, loanId) => {
  const customer = getCustomer(customers, loanId);
  return customer["amount_remaining_ghs"];
};

export const getTotalCollectionsForLoanIdFromTo = (
  collections,
  loan_id,
  fromDate,
  toDate
) => {
  const collection = collections.find(
    (collection) => collection.loan_id === loan_id
  );

  if (!collection) {
    return 0;
  }

  const payments = collection.payments.filter(
    (payment) => payment.date >= fromDate && payment.date <= toDate
  );

  const totalPayment = payments.reduce((acc, curr) => acc + curr.amount, 0);
  return totalPayment;
};

export const getTotalCollectionsOverPeriod = (
  filteredAndSortedWithStatusCollections,
  fromDate,
  toDate
) => {
  const total = filteredAndSortedWithStatusCollections.reduce((acc, curr) => {
    const payments = curr.payments.filter(
      (payment) => payment.date >= fromDate && payment.date <= toDate
    );

    const totalPayment = payments.reduce((acc, curr) => acc + curr.amount, 0);
    return acc + totalPayment;
  }, 0);

  return total.toLocaleString("en-US", {
    style: "currency",
    currency: "GHS",
  });
};

export const getTotalCollectionsFromTo = (collections, fromDate, toDate) => {
  if (!validDateRange(fromDate, toDate)) {
    return;
  }
  let total = 0;

  collections.forEach((collection) => {
    const payments = collection.payments.filter(
      (payment) => payment.date >= fromDate && payment.date <= toDate && payment
    );

    payments.forEach((payment) => {
      total += payment.amount;
    });
  });

  return total;
};

export const getTotalCollectionsFrom = (collections, fromDate) => {
  
  let total = 0;

  collections.forEach((collection) => {
    const payments = collection.payments.filter(
      (payment) => new Date(payment.date) >= new Date(fromDate)  && payment
    );

    payments.forEach((payment) => {
      total += payment.amount;
    });
  });

  return total;
};

export const getDates = (fromDate, toDate, currentReport) => {
  const dates = [];
  let stop = false;
  // array of dates from fromDate to toDate
  const startDate =
    fromDate === "" ? new Date("2023-07-05") : new Date(fromDate);

  const portfolioValueStartDate = new Date("2023-07-05"); // value must include all dates from the beginning of the loan portfolio

  let i = 0;
  while (!stop) {
    // 1 week
    const date =
      currentReport === "Loan_Value"
        ? new Date(portfolioValueStartDate.getTime() + i * 86400000)
        : new Date(startDate.getTime() + i * 86400000);
    dates.push(date.toISOString().slice(0, 10));
    i += 1;
    if (date.toISOString().slice(0, 10) === toDate) {
      stop = true;
    }
  } // while loop

  return { dates, startDate };
};

export const getDailyCollections = (
  collections,
  fromDate,
  toDate,
  currentReport
) => {
  if (!validDateRange()) {
    return;
  }

  const dailyCollections = [];
  const dates = getDates(fromDate, toDate, currentReport).dates;

  dates.forEach((date) => {
    let total = 0;
    collections.forEach((collection) => {
      const payments = collection.payments.filter(
        (payment) => payment.date === date && payment
      );

      payments.forEach((payment) => {
        total += payment.amount;
      });
    });
    dailyCollections.push({ date: date, amount: total });
  });

  const dailyData = [["Date", "Amount"]];
  dailyCollections.forEach((collection) => {
    if (collection.amount !== 0) {
      dailyData.push([collection.date, collection.amount]);
    }
  });

  if (dailyData.length === 1) {
    return [];
  } else {
    return dailyData;
  }
};

export const getTotalDailyLoansValue = (
  customers,
  fromDate,
  toDate,
  currentReport
) => {
  if (!validDateRange()) {
    return;
  }

  const dailyLoanPortfolioValue = [];
  const { dates, startDate } = getDates(fromDate, toDate, currentReport);
  let previousPortfolioValue = 0;

  dates.forEach((date) => {
    let totalLoanPortfolioValue = 0;

    customers.forEach((customer) => {
      if (customer.loan_date.slice(0, 10) === date) {
        totalLoanPortfolioValue +=
          (1.0 + customer.loan_interest_rate / 100.0) *
          customer.loan_amount_ghs;
      }
    });
    dailyLoanPortfolioValue.push({
      date: date,
      amount: totalLoanPortfolioValue + previousPortfolioValue,
    });
    previousPortfolioValue = totalLoanPortfolioValue + previousPortfolioValue;
  });

  const dailyLoanPortfolioValueData = [["Date", "Amount"]];

  dailyLoanPortfolioValue.forEach((loan) => {
    if (loan.amount !== 0 && new Date(loan.date) >= new Date(startDate)) {
      dailyLoanPortfolioValueData.push([loan.date, loan.amount]);
    }
  });

  if (dailyLoanPortfolioValueData.length === 1) {
    return [];
  } else {
    return dailyLoanPortfolioValueData;
  }
}; // total loan value

export const getPortfolioValue = (customers, toDate) => {
  if (!validDateRange()) {
    return;
  }
  const totalLoanPortfolioValue = customers.reduce((acc, curr) => {
    if (
      curr.loan_date.slice(0, 10) >= "2023-07-05" &&
      curr.loan_date.slice(0, 10) <= toDate
    ) {
      acc += (1.0 + curr.loan_interest_rate / 100.0) * curr.loan_amount_ghs;
    }
    return acc;
  }, 0);
  return totalLoanPortfolioValue;
};

export const getTotalFeesForPeriod = (customers, fromDate, toDate) => {
  const totalFees = customers.reduce((acc, curr) => {
    if (
      curr.loan_date.slice(0, 10) >= fromDate &&
      curr.loan_date.slice(0, 10) <= toDate
    ) {
      acc += curr.registration_fee_ghs + curr.insurance_fee_ghs;
    }
    return acc;
  }, 0);
  return totalFees;
};

export const getTotalFeesFrom = (customers, fromDate) => {
  const totalFees = customers.reduce((acc, curr) => {
    if (
      new Date(curr.loan_date.slice(0, 10)) >= new Date(fromDate)
    ) {
      acc += curr.registration_fee_ghs + curr.insurance_fee_ghs;
    }
    return acc;
  }, 0);
  return totalFees;
};

export const getTotalDailyFeesCollected = (
  customers,
  fromDate,
  toDate,
  currentReport
) => {
  if (!validDateRange()) {
    return;
  }

  const dailyFeesCollected = [];
  const { dates } = getDates(fromDate, toDate, currentReport);

  dates.forEach((date) => {
    let totalFees = 0;

    customers.forEach((customer) => {
      if (customer.loan_date.slice(0, 10) === date) {
        totalFees += customer.registration_fee_ghs + customer.insurance_fee_ghs;
      }
    });
    dailyFeesCollected.push({ date: date, amount: totalFees });
  });

  const dailyFeesData = [["Date", "Amount"]];
  dailyFeesCollected.forEach((fee) => {
    if (fee.amount !== 0) {
      dailyFeesData.push([fee.date, fee.amount]);
    }
  });
  if (dailyFeesData.length === 1) {
    return [];
  } else {
    return dailyFeesData;
  }
}; // total daily fees collected

export const getDailyLoansDisbursed = (
  customers,
  fromDate,
  toDate,
  currentReport
) => {
  if (!validDateRange()) {
    return;
  }

  const dailyLoansDisbursed = [];
  const { dates } = getDates(fromDate, toDate, currentReport);

  dates.forEach((date) => {
    let total = 0;

    customers.forEach((customer) => {
      if (customer.loan_date.slice(0, 10) === date) {
        total += customer.loan_amount_ghs;
      }
    });

    dailyLoansDisbursed.push({ date: date, amount: total });
  });

  const dailyData = [["Date", "Amount"]];

  dailyLoansDisbursed.forEach((loan) => {
    if (loan.amount !== 0) {
      dailyData.push([loan.date, loan.amount]);
    }
  });

  if (dailyData.length === 1) {
    return [];
  } else {
    return dailyData;
  }
};

export const getTotalDisbursements = (customers, fromDate, toDate) => {
  if (!validDateRange()) {
    return;
  }

  const total = customers.reduce((acc, curr) => {
    if (
      curr.loan_date.slice(0, 10) >= fromDate &&
      curr.loan_date.slice(0, 10) <= toDate
    ) {
      acc += curr.loan_amount_ghs;
    }
    return acc;
  }, 0);

  return total;
};

export const getTotalDisbursementsFrom = (customers, fromDate) => {

  const total = customers.reduce((acc, curr) => {
    if (
      new Date(curr.loan_date.slice(0, 10)) >= new Date(fromDate)
    ) {
      acc += curr.loan_amount_ghs;
    }
    return acc;
  }, 0);

  return total;
};

export const getTotalNewCustomers = (customers, fromDate, toDate) => {
  if (!validDateRange()) {
    return;
  }

  const ghana_id_numbers = [];

  const total = customers.reduce((acc, customer) => {
    if (
      customer.loan_date.slice(0, 10) >= fromDate &&
      customer.loan_date.slice(0, 10) <= toDate
    ) {
      if (!ghana_id_numbers.includes(customer.ghana_card_id)) {
        acc += 1;
        ghana_id_numbers.push(customer.ghana_id_number);
      }
    }
    return acc;
  }, 0);

  return total;
};

export const getDailyUniqueClientsAcquisition = (
  customers,
  fromDate,
  toDate,
  currentReport
) => {
  if (!validDateRange()) {
    return;
  }

  const dailyUniqueClientsAcquisition = [];
  const ghana_id_numbers = [];

  const { dates } = getDates(fromDate, toDate, currentReport);

  dates.forEach((date) => {
    let new_customers = 0;

    customers.forEach((customer) => {
      if (customer.loan_date.slice(0, 10) === date) {
        if (!ghana_id_numbers.includes(customer.ghana_card_id)) {
          new_customers += 1;
          ghana_id_numbers.push(customer.ghana_id_number);
        }
      }
    });

    dailyUniqueClientsAcquisition.push({
      date: date,
      num_of_customers: new_customers,
    });
  });

  const dailyData = [["Date", "Amount"]];
  dailyUniqueClientsAcquisition.forEach((new_customer_obj) => {
    dailyData.push([new_customer_obj.date, new_customer_obj.num_of_customers]);
  });

  if (dailyData.length === 1) {
    return [];
  }
  return dailyData;
};

