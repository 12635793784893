import React from "react";

const Spinner = (props) => {

    return (
        <div className='fc fdv jc aic loaderDiv' id={props.id ? props.divId : 'spinner'}>
            <div className='loader' id= {props.loaderId ? props.loaderId :'loginRegistrationPageLoader'}></div>
            <h5 className='fc jc'>...{props.displayText || 'Loading'}... </h5>
        </div>
    )
}

export default Spinner