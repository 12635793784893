/**
 *
 * @param {*} currentDate: optional, defaults to current date
 * @param {*} months: required, number of months to add to current date
 * @returns: new date with months added
 */
export const addMonthsToCurrentDate = (currentDate = new Date(), months) => {
  if (typeof currentDate === "string") {
    currentDate = new Date(currentDate);
  }
  return new Date(
    currentDate.setMonth(currentDate.getMonth() + parseInt(months))
  );
};

export const ratesObject = {
  1: 5,
  6: 12,
  12: 30,
  18: 100,
  24: 200,
};

export const computeInvestmentAccountValueToDate = (
  initialAmount,
  rate,
  startDate,
  endDate
) => {
  const yearDiff =
    parseInt(endDate.getFullYear() - startDate.getFullYear()) * 12;
  const monthDiff = parseInt(endDate.getMonth() - startDate.getMonth());
  const maturityMonths = yearDiff + monthDiff;

  if (endDate > new Date(Date.now())) {
    endDate = new Date(Date.now());
  }

  const months =
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    (endDate.getMonth() - startDate.getMonth());
  const interest = (initialAmount * rate * months) / maturityMonths / 100.0;
  const value = parseFloat(initialAmount) + interest;
  return value;
};
