import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useImageLoader from "../../hooks/useImageLoader";
import { parseBoolean, setTokenAndUser } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setAuthToken,
  setAuthUser,
} from "../../reduxSlices/slices/authSlice";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Spinner from "../../smallComponents/Spinner";
import logo from "../../images/tamicro_loans_logo_svg.svg";
import google_g from "../../images/google_g.png";
import Navbar from "../../smallComponents/navBar/Navbar";

const Login = () => {
  const [opacity, setFinishedLoading] = useImageLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [googleUser, setGoogleUser] = useState({});
  const [googleProfile, setGoogleProfile] = useState({});
  const dispatch = useDispatch();
  const is_authenticated = useSelector((state) =>
    parseBoolean(state.isAuthenticated.auth.value)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (is_authenticated === true || is_authenticated === "true") {
      navigate("/");
    }
  }, [is_authenticated, navigate]);

  const setAuthenticatication = (bool) => {
    return dispatch(setIsAuthenticated(bool));
  };

  const postLogin = async (url, data = {}) => {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };

  const loginFormik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: async (values) => {
      setIsLoading(true);
      postLogin("/api/login", {
        details: {
          email: values.email,
          password: values.password,
        },
      }).then((data) => {
        if (data.status === "success") {
          setIsLoading(false);
          setTokenAndUser(
            data.token,
            values.email,
            setAuthToken,
            setAuthUser,
            dispatch
          );
          setAuthenticatication("true");
          navigate("/");
        } else {
          setIsLoading(false);
          setAuthenticatication("false");
          if (data.redirect && data.redirect === "/register") {
            alert("You are not registered; Please register");
            navigate("/register");
          } else {
            alert("Incorrect email or password");
          }
        }
      });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol")
        .required("Required"),
    }),
  });

  const clearInput = (val) => {
    loginFormik.values[val] = "";
    loginFormik.setTouched({ val });
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setGoogleUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // log out function to log the user out of google and set the profile array to null
  const logOutofGoogle = () => {
    googleLogout();
    setGoogleProfile(null);
  };

  useEffect(() => {
    if (googleUser.access_token) {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${googleUser.access_token}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setGoogleProfile(res);
        })
        .catch((err) => console.log(err));
    }
  }, [googleUser]);

  useEffect(() => {
    const setAuthenticatication = (bool) => {
      return dispatch(setIsAuthenticated(bool));
    };

    const registerOrLoginWithGoogle = (profile) => {
      const url = "/api/registerOrLoginWithGoogle";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAuthenticatication("true");
            setTokenAndUser(
              data.token,
              data.email,
              setAuthToken,
              setAuthUser,
              dispatch
            );
            navigate("/");
          } else {
            setAuthenticatication("false");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (googleProfile && googleProfile.name && googleProfile.email) {
      const profile = {
        name: googleProfile.name,
        email: googleProfile.email,
      };
      registerOrLoginWithGoogle(profile);
      logOutofGoogle();
    }
  }, [googleProfile, dispatch, navigate]);

  return (
    <>
      <Navbar />
      <div className="fc fdv aic jc loginOrRegisterPage">
        <div className="fc loginPageMainDiv" id="loginPageMainDiv">
          <div className="fc fdv aic cardLoginDiv" id="cardLoginDiv">
            <form
              className="fc fdv aic cardLoginForm"
              id="cardLoginForm"
              onSubmit={loginFormik.handleSubmit}
            >
              <div className="fc jc aic loginCardHeader" id="loginCardHeader">
                <span className="material-symbols-sharp">
                  enhanced_encryption
                </span>
                <span>Login to Tamicroloans Inc.</span>
              </div>
              <div className="fc jc aic cardLoginImageDiv">
                <img
                  className="w3-image w3-animate-zoom loginImage"
                  src={logo}
                  alt="login"
                  onLoad={() => {
                    setFinishedLoading();
                  }}
                  style={{ opacity: opacity }}
                />
              </div>
              <div className="fc fdv aic" id="loginInputs">
                <div className="fc aic jfe loginInputsDiv">
                  <div className="fc aic inputDiv">
                    <input
                      type="email"
                      name="email"
                      placeholder="name@email.com"
                      autoComplete="off"
                      onChange={loginFormik.handleChange}
                      onBlur={loginFormik.handleBlur}
                      value={loginFormik.values.email}
                      id="emailInput"
                      disabled
                    />
                  </div>
                  <div className="fc aic backspaceDiv">
                    {loginFormik.values.email && (
                      <span
                        className="material-symbols-outlined"
                        style={{
                          fontSize: "16px",
                          color: "#3AA9E8",
                        }}
                        onClick={() => clearInput("email")}
                      >
                        refresh
                      </span>
                    )}
                  </div>
                </div>
                <div className="fc jc aic formikOuterErrorDiv">
                  {loginFormik.touched.email && loginFormik.errors.email ? (
                    <div style={{ color: "red" }} className="formikErrorDiv">
                      {loginFormik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="fc aic jc loginInputsDiv">
                  <div className="fc aic inputDiv">
                    <input
                      type="password"
                      name="password"
                      placeholder="password"
                      autoComplete="off"
                      onChange={loginFormik.handleChange}
                      onBlur={loginFormik.handleBlur}
                      value={loginFormik.values.password}
                      disabled
                    />
                  </div>
                  <div className="fc aic backspaceDiv">
                    {loginFormik.values.password && (
                      <span
                        className="material-symbols-outlined"
                        style={{
                          fontSize: "16px",
                          color: "#3AA9E8",
                        }}
                        onClick={() => clearInput("password")}
                      >
                        refresh
                      </span>
                    )}
                  </div>
                </div>
                <div className="fc jc aic formikOuterErrorDiv">
                  {loginFormik.touched.password &&
                  loginFormik.errors.password ? (
                    <div style={{ color: "red" }} className="formikErrorDiv">
                      {loginFormik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="fc jc aic loginCardButtonDiv">
                <button
                  className="w3-button w3-card w3-round-xxlarge loginCardButton"
                  id="loginCardButton"
                  type="submit"
                  disabled
                >
                  Submit
                </button>
              </div>
              <div className="switchLoginAndRegister">
                Not a Member?&nbsp;
                <Link to="/register" className="w3-text-blue" id="signUpLink">
                  Sign Up
                </Link>
              </div>
            </form>
            <div
              className="fc aic jc loginCardFooter w3-card"
              id="loginCardFooter"
            >
              <button
                className="w3-button"
                id="loginWithGoogleButton"
                onClick={loginWithGoogle}
              >
                <img
                  className="w3-image w3-animate-zoom google_g"
                  src={google_g}
                  alt="google_g"
                />
                Login with Google
              </button>
            </div>
          </div>
        </div>
        {isLoading && <Spinner displayText="Logging In" />}
      </div>
    </>
  );
};

export default Login;
