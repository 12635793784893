export const getTotalCustomerCollections = (collections, loan_id) => {
  let total = 0;
  const collection = collections.find(
    (collection) => collection.loan_id === loan_id
  );
  if (collection) {
    const paymentsArray = collection.payments || [];
    paymentsArray.forEach((payment) => {
      total += payment.amount;
    });
  }
  return total;
};

export const getTotalOutstandingBalance = (customers, collections) => {
  let totalOustandingBalance = 0;

  customers.forEach((customer) => {
    totalOustandingBalance +=
      customer.amount_remaining_ghs -
      getTotalCustomerCollections(collections, customer.loan_id);
  });

  return totalOustandingBalance;
};

export const getTotalCollections = (collections) => {
  let totalCollections = 0;

  collections.forEach((collection) => {
    const paymentsArray = collection.payments || [];
    paymentsArray.forEach((payment) => {
      totalCollections += payment.amount;
    });
  });

  return totalCollections;
};

export const getTotalFeesCollected = (customers) => {
  const totalFees = customers.reduce((acc, curr) => {
    acc += curr.registration_fee_ghs + curr.insurance_fee_ghs;
    return acc;
  }, 0);
  return totalFees;
};

export const getTotalInvestments = (investment_accounts) => {
  let totalInvestments = 0;
  investment_accounts.forEach((investment_account) => {
    totalInvestments += investment_account.amount;
  });

  return totalInvestments;
};

export const getTotalInvestmentFromDate = (investment_accounts, fromDate) => {
  let totalInvestments = 0;

  investment_accounts.forEach((investment_account) => {
    const inv_date = new Date(investment_account.openingDate.split("T")[0]);
    const from = new Date(fromDate);

    if (from > inv_date) {
      totalInvestments += investment_account.amount;
    }
  });
  return totalInvestments;
};

export const getTotalPortfolioValue = (customers) => {
  let totalPortfolio = 0;

  customers.forEach((customer) => {
    totalPortfolio += customer.amount_remaining_ghs;
  });

  return totalPortfolio;
};

export const getTotalLoansDisbursed = (customers) => {
  let totalLoans = 0;

  customers.forEach((customer) => {
    totalLoans += customer.loan_amount_ghs;
  });

  return totalLoans;
};

export const getSortedCustomers = (
  customers,
  sortDirection,
  sortingParameter
) => {
  return [...customers].sort((a, b) => {
    if (sortDirection === "ascending") {
      if (a[sortingParameter] + "".trim() < b[sortingParameter] + "".trim()) {
        return -1;
      } else if (
        a[sortingParameter] + "".trim() >
        b[sortingParameter] + "".trim()
      ) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a[sortingParameter] + "".trim() < b[sortingParameter] + "".trim()) {
        return 1;
      } else if (
        a[sortingParameter] + "".trim() >
        b[sortingParameter] + "".trim()
      ) {
        return -1;
      } else {
        return 0;
      }
    }
  });
};

export const getTotalExpensesBasedOnCategory = (expenses_array, category) => {
  let total = 0;
  expenses_array.forEach((expense) => {
    if (expense.category === category) {
      total += expense.amount;
    }
  });
  return total;
};

export const getTotalExpenses = (expenses) => {
  let totalExpenses = 0;
  expenses.forEach((expense) => {
    totalExpenses += expense.amount;
  });
  return totalExpenses;
};

export const getTotalExpensesFromDate = (expenses, fromDate) => {
  let totalExpenses = 0;
  expenses.forEach((expense) => {
    if (new Date(expense.date.slice(0, 10)) >= new Date(fromDate)) {
      totalExpenses += expense.amount;
    }
  });
  return totalExpenses;
};

export const getTotalExpensesFromDateMinusBankTransactions = (
  expenses,
  fromDate
) => {
  let totalExpenses = 0;
  expenses.forEach((expense) => {
    if (new Date(expense.date.slice(0, 10)) >= new Date(fromDate)) {
      if (expense.category === "bank_withdrawal") {
        totalExpenses += 0;
      } else if (expense.category === "bank_deposit") {
        totalExpenses += 0;
      } else {
        totalExpenses += expense.amount
      };
    }
  });
  return totalExpenses;
};

export const getTotalDepositsFrom = (expenses, fromDate) => {
    let total_deposits = 0

    expenses.forEach((expense) => {
      if (new Date(expense.date.slice(0, 10)) >= new Date(fromDate) && expense.category === "bank_deposit") {
        total_deposits += expense.amount;
      }
    });

    return total_deposits;
}

export const getTotalWithdrawalsFrom = (expenses, fromDate) => {
  let total_withdrawal = 0

  expenses.forEach((expense) => {
    if (new Date(expense.date.slice(0, 10)) >= new Date(fromDate) && expense.category === "bank_withdrawal") {
      total_withdrawal += expense.amount;
    }
  });

  return total_withdrawal;
}
