import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRatings } from "../../reduxSlices/slices/ratingsSlice";

const Ratings = () => {
  const customers = useSelector((state) => state.customer.customers.value);
  const ratings = useSelector((state) => state.ratings.ratings.value);
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );
  const [inEditModeIndex, setInEditModeIndex] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [currentComment, setCurrentComment] = useState("");
  const [sortBy, setSortBy] = useState("ghana_card_id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterBy, setFilterBy] = useState("All");
  const [filteredAndSortedCustomers, setFilteredAndSortedCustomers] =
    useState(customers);

  const dispatch = useDispatch();

  const ratingsEnum = {
    1: "Very Bad",
    2: "Bad",
    3: "Good",
    4: "Very Good",
    5: "Excellent",
  };

  useEffect(() => {
    const ratingsEnum1 = {
      1: "Very Bad",
      2: "Bad",
      3: "Good",
      4: "Very Good",
      5: "Excellent",
    };

    if (filterBy === "All") {
      setFilteredAndSortedCustomers(customers);
    } else {
      setFilteredAndSortedCustomers(
        customers.filter((customer) => {
          const tempCustomer = ratings.find(
            (rating) => rating.ghana_card_id === customer.ghana_card_id
          );

          if (
            tempCustomer &&
            [1, 2, 3, 4, 5].includes(tempCustomer.rating) &&
            tempCustomer.rating === parseInt(filterBy)
          ) {
            return tempCustomer;
          }
          return null;
        })
      );
    }
  }, [customers, filterBy, ratings]);

  const saveRating = async (ratingData) => {
    console.log("ratingData", ratingData);
    const response = await fetch("/api/ratings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
      body: JSON.stringify(ratingData),
    });

    const data = await response.json();
    if (data.status === 200) {
      console.log("data", data);

      const tempRatings = [...ratings];
      //new rating
      tempRatings.push({
        ghana_card_id: ratingData.ghana_card_id,
        rating: ratingData.rating,
        comment: ratingData.comment,
      });

      dispatch(setRatings(tempRatings));
    } else if (data.status === 409) {
      updateRating();
    } else {
      console.log("error");
    }
  };

  const updateRating = async (ratingData) => {
    const response = await fetch("/api/ratings/" + ratingData.ghana_card_id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
      body: JSON.stringify(ratingData),
    });

    const data = await response.json();
    if (data.status === 200) {
      const tempRatings = [...ratings];

      const index = tempRatings.findIndex(
        (tempRating) => tempRating.ghana_card_id === ratingData.ghana_card_id
      );
      //update rating
      if (index !== -1) {
        tempRatings[index] = {
          ghana_card_id: ratingData.ghana_card_id,
          rating: ratingData.rating,
          comment: ratingData.comment,
        };
        dispatch(setRatings(tempRatings));
      } else {
        console.log("An error occured or Rating not found");
      }
    }
  };

  const findRating = (ghana_card_id) => {
    const tempRating = ratings.find(
      (rating) => rating.ghana_card_id === ghana_card_id
    );
    if (tempRating) {
      return tempRating.rating;
    }
    return null;
  };

  //return a table of customer Names, id, ratings (which can be edited using a dropdown)
  return (
    <div className="w3-mobile ratingsTableWrapperDiv">
      {inEditModeIndex !== null && (
        <div className="fc jfe aic" style={{ width: "100%" }}>
          <button
            className="w3-button w3-white  w3-border w3-round-xxlarge w3-hover-sepia w3-margin-top ratingsTableCancelBtn"
            onClick={() => setInEditModeIndex(null)}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="fc jfs aic w3-margin-top w3-text-blue">
        Num of records: {filteredAndSortedCustomers.length}
      </div>
      <table className="w3-table w3-striped w3-hoverable w3-centered ratingsTable w3-bordered">
        <thead>
          <tr>
            <th>
              <button className="w3-button w3-white w3-border">
                Customer ID
              </button>
            </th>
            <th>
              <button className="w3-button w3-white w3-border">
                Last Name
              </button>
            </th>
            <th>
              <button className="w3-button w3-white w3-border">
                First Name
              </button>
            </th>
            <th>
              <button className="w3-button w3-white w3-border">
                {/** Filterable dropdown using ratings enum values*/}
                Rating: &nbsp;
                <select
                  onChange={(e) => {
                    setFilterBy(e.target.value);
                  }}
                  value={filterBy || "All"}
                >
                  <option value="All">All</option>
                  {Object.entries(ratingsEnum).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </button>
            </th>
            <th>Comment</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedCustomers.map((customer, index) => (
            <tr key={customer._id}>
              <td>{customer.ghana_card_id}</td>
              <td>{customer.last_name}</td>
              <td>{customer.first_name}</td>
              <td>
                {inEditModeIndex === index ? (
                  <select
                    onChange={(e) => {
                      setSelectedRating(e.target.value);
                    }}
                    value={selectedRating || ""}
                  >
                    {Object.entries(ratingsEnum).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : ratings.find(
                    (rating) => rating.ghana_card_id === customer.ghana_card_id
                  ) ? (
                  <span
                    className={
                      findRating(customer.ghana_card_id) > 2
                        ? findRating(customer.ghana_card_id) > 3
                          ? "w3-yellow"
                          : "w3-text-green"
                        : "w3-text-red"
                    }
                  >
                    {
                      ratingsEnum[
                        ratings.find(
                          (rating) =>
                            rating.ghana_card_id === customer.ghana_card_id
                        ).rating
                      ]
                    }
                  </span>
                ) : (
                  "Not rated yet"
                )}
              </td>
              <td>
                {inEditModeIndex === index ? (
                  <input
                    type="text"
                    onChange={(e) => {
                      setCurrentComment(e.target.value);
                    }}
                    maxLength={25}
                  />
                ) : ratings.find(
                    (rating) => rating.ghana_card_id === customer.ghana_card_id
                  ) ? (
                  <span
                    className={
                      findRating(customer.ghana_card_id) > 2
                        ? findRating(customer.ghana_card_id) > 3
                          ? "w3-yellow"
                          : "w3-text-green"
                        : "w3-text-red"
                    }
                  >
                    {
                      ratings.find(
                        (rating) =>
                          rating.ghana_card_id === customer.ghana_card_id
                      ).comment
                    }
                  </span>
                ) : (
                  "No comment yet"
                )}
              </td>
              <td className="ratingsTableActionTd">
                {inEditModeIndex === index ? (
                  <span
                    className="material-symbols-outlined w3-hover-sepia w3-border w3-round-xxlarge"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const tempRating = {
                        ghana_card_id: customer.ghana_card_id,
                        rating: selectedRating,
                        comment: currentComment,
                      };
                      setInEditModeIndex(null);
                      setSelectedRating(null);
                      setCurrentComment("");

                      const previousRating = ratings.find(
                        (rating) =>
                          rating.ghana_card_id === customer.ghana_card_id
                      );
                      if (previousRating) {
                        if (tempRating.rating === null) {
                          tempRating.rating = previousRating.rating;
                        }
                        if (tempRating.comment === "") {
                          tempRating.comment = previousRating.comment;
                        }
                        updateRating(tempRating);
                      } else {
                        saveRating(tempRating);
                      }
                    }}
                  >
                    save
                  </span>
                ) : (
                  <span
                    className="material-symbols-outlined w3-hover-sepia w3-border w3-round-xxlarge"
                    style={{ cursor: "pointer" }}
                    onClick={() => setInEditModeIndex(index)}
                  >
                    edit
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* </div> */}
    </div>
  );
};

export default Ratings;
