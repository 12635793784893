import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: {
      value: {
        last_name: "",
        first_name: "",
        ghana_card_id: "",
        phone: "",
        loan_number: 1,
        loan_id: "",
        loan_date: "",
        loan_amount_ghs: 0,
        loan_duration_wks: 0,
        registration_fee_ghs: 0,
        insurance_fee_ghs: 0,
        loan_interest_rate: 0,
        amount_remaining_ghs: 0,
      },
    },
    customers: { value: [] },
  },
  reducers: {
    setCustomer: (state, customer = {}) => {
      if (state.customer.value !== customer.payload) {
        state.customer.value = customer.payload;
      }
    },
    setCustomers: (state, customers = []) => {
      if (
        JSON.stringify(state.customers.value) !==
        JSON.stringify(customers.payload)
      ) {
        state.customers.value = customers.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCustomer, setCustomers } = customerSlice.actions;

export default customerSlice.reducer;
