import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCustomer, setCustomers } from "../reduxSlices/slices/customerSlice";
import Navbar from "../smallComponents/navBar/Navbar";

const CustomerInfo = () => {
  const customer = useSelector((state) => state.customer.customer.value);
  const customers = useSelector((state) => state.customer.customers.value);
  const applicationMode = useSelector(
    (state) => state.applicationMode.applicationMode.value
  );
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );

  const [localCustomer, setLocalCustomer] = useState(customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localCustomer.contact_person === undefined) {
      setLocalCustomer({
        ...localCustomer,
        contact_person: {
          name: "",
          phone: "",
          ghana_card_id: "",
          relationship: "",
        },
      });
    }
  }, [localCustomer]);

  useEffect(() => {
    if (
      (applicationMode.mode === "new_applicant" ||
        applicationMode.mode === "new_application") &&
      localCustomer.loan_amount_ghs > 0 &&
      localCustomer.loan_interest_rate > 0 &&
      localCustomer.amount_remaining_ghs !==
        localCustomer.loan_amount_ghs *
          (1 + localCustomer.loan_interest_rate / 100)
    ) {
      setLocalCustomer({
        ...localCustomer,
        amount_remaining_ghs:
          localCustomer.loan_amount_ghs *
          (1 + localCustomer.loan_interest_rate / 100),
      });
    }
  }, [localCustomer.loan_interest_rate, applicationMode.mode, localCustomer]);

  useEffect(() => {
    if (customers.length > 0) {
      if (localCustomer.loan_id === "" || localCustomer.loan_id === undefined) {
        if (
          applicationMode.mode === "new_applicant" ||
          applicationMode.mode === "new_application"
        ) {
          let loan_id = "TA";
          if (customers.length < 10) {
            loan_id += "0";
          }
          loan_id += (customers.length + 1).toString();
          setLocalCustomer({
            ...localCustomer,
            loan_id: loan_id,
          });
        }
      }
    }
  }, [applicationMode.mode, customers.length, localCustomer]);

  const saveCustomer = async () => {
    // send fetch to /api/customer to save client
    // if applicationMode.mode === 'new_applicant' then send fetch to /api/application to save application with post mode
    // if applicationMode.mode === 'existing_application' then send fetch to /api/application to save application with put mode
    // if applicationMode.mode === 'new_application' then send fetch to /api/application to save application with put mode and update client.applications

    // start
    const response = await fetch("/api/customer", {
      method:
        applicationMode.mode === "new_applicant" ||
        applicationMode.mode === "new_application"
          ? "POST"
          : "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
      body: JSON.stringify(localCustomer),
    });

    if (response.status === 403 || response.status === 401) {
      alert("error saving client: try logging in again");
      navigate("/login");
    } else if (response.status === 200) {
      dispatch(setCustomers([...customers, localCustomer]));
      setLocalCustomer({
        last_name: "",
        first_name: "",
        ghana_card_id: "",
        phone: "",
        loan_id: "",
        loan_date: "",
        loan_number: 1,
        loan_amount_ghs: 0,
        loan_duration_wks: 0,
        registration_fee_ghs: 0,
        insurance_fee_ghs: 0,
        loan_interest_rate: 0,
        amount_remaining_ghs: 0,
        contact_person: {
          name: "",
          phone: "",
          ghana_card_id: "",
          relationship: "",
        },
        digital_address: "",
      });
      alert("client saved successfully");
      navigate("/");
    }
  };

  return (
    <>
      <Navbar />
      <div className="fc fdv aic jc applicantInfoDiv">
        <h1>Customer Information</h1>

        <div className="fc fdv asb newCustomerForm w3-card">
          <div className="fc aic jsb">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={localCustomer.last_name}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  last_name: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={localCustomer.first_name}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  first_name: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="ghana_card_id">Ghana Card ID</label>
            <input
              type="text"
              id="ghana_card_id"
              name="ghana_card_id"
              value={localCustomer.ghana_card_id}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  ghana_card_id: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={localCustomer.phone}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_id">Loan ID</label>
            <span className="fc jc aic loan_id_span">
              {localCustomer.loan_id}
            </span>
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_id">Loan #</label>
            <span className="fc jc aic loan_id_span">
              {localCustomer.loan_number}
            </span>
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_date">Loan Date</label>
            <input
              type="date"
              id="loan_date"
              name="loan_date"
              value={localCustomer.loan_date.split("T")[0]}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  loan_date: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_amount_ghs">Loan Amount (GHS)</label>
            <input
              type="number"
              id="loan_amount_ghs"
              name="loan_amount_ghs"
              value={
                localCustomer.loan_amount_ghs === 0
                  ? localCustomer.loan_amount_ghs
                  : (localCustomer.loan_amount_ghs + "").replace(/^0+/, "")
              }
              min={0}
              onChange={(e) => {
                // Handle the case where the input is cleared
                setLocalCustomer({
                  ...localCustomer,
                  loan_amount_ghs: Number(e.target.value),
                });
              }}
              disabled={
                applicationMode.mode !== "new_application" &&
                applicationMode.mode !== "new_applicant"
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_duration_wks">Loan Duration (Weeks)</label>
            <input
              type="number"
              min={1}
              id="loan_duration_wks"
              name="loan_duration_wks"
              value={
                localCustomer.loan_duration_wks === 0
                  ? localCustomer.loan_duration_wks
                  : (localCustomer.loan_duration_wks + "").replace(/^0+/, "")
              }
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  loan_duration_wks: parseInt(e.target.value),
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="registration_fee_ghs">Registration Fee (GHS)</label>
            <input
              type="number"
              min={0}
              id="registration_fee_ghs"
              name="registration_fee_ghs"
              value={
                localCustomer.registration_fee_ghs === 0
                  ? localCustomer.registration_fee_ghs
                  : (localCustomer.registration_fee_ghs + "").replace(/^0+/, "")
              }
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  registration_fee_ghs: parseInt(e.target.value),
                })
              }
              disabled={
                applicationMode.mode !== "new_application" &&
                applicationMode.mode !== "new_applicant"
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="insurance_fee_ghs">Insurance Fee (GHS)</label>
            <input
              type="number"
              min={0}
              id="insurance_fee_ghs"
              name="insurance_fee_ghs"
              value={
                localCustomer.insurance_fee_ghs === 0
                  ? localCustomer.insurance_fee_ghs
                  : (localCustomer.insurance_fee_ghs + "").replace(/^0+/, "")
              }
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  insurance_fee_ghs: parseInt(e.target.value),
                })
              }
              disabled={
                applicationMode.mode !== "new_application" &&
                applicationMode.mode !== "new_applicant"
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="loan_interest_rate">Loan Interest Rate</label>
            <input
              type="number"
              min={0}
              id="loan_interest_rate"
              name="loan_interest_rate"
              value={
                localCustomer.loan_interest_rate === 0
                  ? localCustomer.loan_interest_rate
                  : (localCustomer.loan_interest_rate + "").replace(/^0+/, "")
              }
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  loan_interest_rate: Number(e.target.value),
                })
              }
              disabled={
                applicationMode.mode !== "new_application" &&
                applicationMode.mode !== "new_applicant"
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="contact_person_name">Contact Person Name</label>
            <input
              type="text"
              id="contact_person_name"
              name="contact_person_name"
              value={localCustomer.contact_person?.name ?? ""}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  contact_person: localCustomer.contact_person
                    ? { ...localCustomer.contact_person, name: e.target.value }
                    : {
                        name: e.target.value,
                        phone: "",
                        ghana_card_id: "",
                        relationship: "",
                      },
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="contact_person_phone">Contact Person Phone</label>
            <input
              type="text"
              id="contact_person_phone"
              name="contact_person_phone"
              value={localCustomer.contact_person?.phone ?? ""}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  contact_person: {...localCustomer.contact_person, phone: e.target.value},
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="contact_person_ghana_card_id">
              Contact Person ID
            </label>
            <input
              type="text"
              id="contact_person_ghana_card_id"
              name="contact_person_ghana_card_id"
              value={localCustomer.contact_person?.ghana_card_id ?? ""}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  contact_person: {...localCustomer.contact_person, ghana_card_id: e.target.value},
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="contact_person_relationship">
              Contact Person relationship
            </label>
            <input
              type="text"
              id="contact_person_relationship"
              name="contact_person_relationship"
              value={localCustomer.contact_person?.relationship ?? ""}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  contact_person: {...localCustomer.contact_person, relationship: e.target.value},
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="digital_address">Digital Address</label>
            <input
              type="text"
              id="digital_address"
              name="digital_address"
              value={localCustomer.digital_address ?? ""}
              onChange={(e) =>
                setLocalCustomer({
                  ...localCustomer,
                  digital_address: e.target.value,
                })
              }
            />
          </div>
          <div className="fc aic jsb">
            <label htmlFor="amount_remaining_ghs">Amount Remaining (GHS)</label>
            <span className="fc jc aic amount_remaining_span">
              {(localCustomer.amount_remaining_ghs).toFixed(2)}
            </span>
          </div>
          <div className="fc jc aic saveCustomerDiv">
            <button
              className="w3-button w3-green"
              onClick={() => {
                saveCustomer(localCustomer);
                // setEditMode(false);
              }}
            >
              Save
            </button>
            <button
              className="w3-button w3-red"
              onClick={() => {
                setCustomer({
                  last_name: "",
                  first_name: "",
                  ghana_card_id: "",
                  phone: "",
                  loan_number: 1,
                  loan_id: "",
                  loan_date: "",
                  loan_amount_ghs: 0,
                  loan_duration_wks: 0,
                  registration_fee_ghs: 0,
                  insurance_fee_ghs: 0,
                  loan_interest_rate: 0,
                  amount_remaining_ghs: 0,
                  contact_person: {
                    name: "",
                    phone: "",
                    ghana_card_id: "",
                    relationship: "",
                  },
                  digital_address: "",
                });
                navigate("/");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
