import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  collectionsOnSetDate,
  getCustomer,
  getCollectionForLoanIdOnDate,
} from "./reportsUtilFunctions";

const DailyCollections = () => {
  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );

  const customers = useSelector((state) => state.customer.customers.value);

  const collections = useSelector(
    (state) => state.collection.collections.value
  );

  const [currency, setCurrency] = useState("GHS");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  return (
    <div className="w3-responsive" style={{ marginTop: "20px" }}>
      <div className="fc jc aic reportsCollectionsTableHeader">
        <div className="fc w3-padding-32">
          <span>Currency: &nbsp;</span>
          <select
            name="yesNo1"
            id="yesNo1"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option value="USD">usd</option>
            <option value="CAD">cad</option>
            <option value="EUR">eur</option>
            <option value="GBP">gbp</option>
            <option value="GHS">ghs</option>
          </select>
        </div>
        <div className="fc reportCollectionsFilterDates">
          <div className="fc fdv aic reportCollectionsFilterInputs">
            <input
              type="date"
              className="w3-input reportInput"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={date}
            />
          </div>
        </div>
      </div>
      <div className="fc jc aic w3-responsive">
        <table className="w3-table-all collectionsReportTable w3-responsive">
          <thead className="collectionsReportHead">
            <tr>
              <th className="reportHeader">#</th>
              <th className="reportHeader" onClick={() => {}}>
                Loan ID
              </th>
              <th className="reportHeader">First Name</th>
              <th className="reportHeader">Last Name</th>
              <th className="reportHeader">
                Amt Paid (<span className="w3-text-red">{currency}</span>)
              </th>
            </tr>
          </thead>
          <tbody>
            {collectionsOnSetDate(collections, date).map(
              (collection, index) => (
                <tr key={index} className="collectionsReportTableRow">
                  <td className="reportData">{index + 1}</td>
                  <td className="reportData">{collection.loan_id}</td>
                  <td className="reportData">
                    {getCustomer(customers, collection.loan_id)?.first_name}{" "}
                  </td>
                  <td className="reportData">
                    {getCustomer(customers, collection.loan_id)?.last_name}
                  </td>
                  <td
                    key={index}
                    className="reportData"
                    style={{ color: "green" }}
                  >
                    {(
                      getCollectionForLoanIdOnDate(
                        collections,
                        collection.loan_id,
                        date
                      ) * exchangeRates[currency]
                    ).toFixed(2)}
                  </td>
                </tr>
              )
            )}
          </tbody>
          <tfoot id="collectionsReportFooter">
            <tr id="collectionsReportFinalRow">
              <td className="reportData">Total</td>
              <td
                className="reportData w3-blue"
                colSpan={13}
                style={{ textAlign: "end" }}
              >
                {(
                  collectionsOnSetDate(collections, date).reduce(
                    (acc, curr) =>
                      acc +
                      getCollectionForLoanIdOnDate(
                        collections,
                        curr.loan_id,
                        date
                      ),
                    0
                  ) * exchangeRates[currency]
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default DailyCollections;
