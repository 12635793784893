import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setExchangeRates } from "../../reduxSlices/slices/exchangeRatesSlice";

import Navbar from "../../smallComponents/navBar/Navbar";

const Projections = () => {
  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );

  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]); //{numCustomers: 0, delta_customers: 0, , new_loans: 0, payment_received: 0, outstanding: 0, fees_collected: 0, amount_to_invest: 0, market_cap: 0, cash_remaining: 0}
  const [principal, setPrincipal] = useState(0);
  const [numWeeks, setNumWeeks] = useState(0);
  const [total_unique_customers, setTotalUniqueCustomers] = useState(0);
  const num_weeks = 20; // 20 weeks
  const [currency, setCurrency] = useState("GHS");
  const [editExchangeRatesMode, setEditExchangeRatesMode] = useState(false);
  const [average_num_of_customers, setAverageNumOfCustomers] = useState(0);
  const [loan_amount, setLoanAmount] = useState(2000);
  const [reg_fee, setRegFee] = useState(0.03 * loan_amount);
  const [mthly_interest_rate, setMthlyInterestRate] = useState(0.05);
  const [insurance_fee, setInsuranceFee] = useState(0.02 * loan_amount);
  const [total_fees, setTotalFees] = useState(insurance_fee + reg_fee);
  const [total_interest, setTotalInterest] = useState(
    mthly_interest_rate * (num_weeks / 4) * loan_amount
  );
  const [num_of_agents, setNumOfAgents] = useState(2);
  const [include_edtech, setIncludeEdtech] = useState("No");
  const [edtech_price, setEdtechPrice] = useState(30);
  const [weekly_budget, setWeeklyBudget] = useState(400);

  useEffect(() => {
    if (parseInt(security_level) < 2) {
      alert("You are not authorized to view this page");
      navigate("/");
    }
  }, [navigate, security_level]);

  useEffect(() => {
    if (principal >= loan_amount && numWeeks > 0) {
      let tempRows = [];
      let tempPrincipal = principal;
      let tempNumWeeks = numWeeks;
      let amount_to_invest = tempPrincipal;
      const new_unique_customers = [];
      let total_num_customers = 0;

      for (let i = 0; i <= tempNumWeeks; i++) {
        let tempRow = {};
        if (i === 0) {
          // num Customers
          tempRow.numCustomers = Math.floor(amount_to_invest / loan_amount);
          total_num_customers += tempRow.numCustomers;
          //delta customers
          tempRow.delta_customers = tempRow.numCustomers;

          if (tempRow.delta_customers > 0) {
            new_unique_customers.push(tempRow.delta_customers);
          }

          //new loans = tempRow.delta_customers * loan_amount
          tempRow.new_loans = tempRow.delta_customers * loan_amount;

          //fees collected = tempRow.delta_customers * (reg_fee + insurance_fee)
          tempRow.fees_collected = tempRow.delta_customers * total_fees;

          //payment received = 0
          tempRow.payment_received = 0;

          //outstanding = tempRow.numCustomers * (loan_amount + (loan_amount * (mthly_interest_rate/4)))
          const weekly_interest = loan_amount * (mthly_interest_rate / 4);
          tempRow.outstanding =
            tempRow.numCustomers * (loan_amount + num_weeks * weekly_interest);

          //amount to invest = tempRow.payment_received + tempRow.fees_collected + (principal - tempRow.new_loans)
          tempRow.amount_to_invest =
            tempRow.payment_received +
            tempRow.fees_collected +
            (principal - tempRow.new_loans);

          //market cap = tempRow.outstanding + tempRow.amount_to_invest
          tempRow.market_cap = tempRow.outstanding + tempRow.amount_to_invest;

          //cash remaining = tempRow.payment_received + tempRow.fees_collected
          tempRow.cash_remaining = tempRow.amount_to_invest;
        } else {
          tempRow.delta_customers = Math.floor(
            tempRows[i - 1].amount_to_invest / loan_amount
          );
          if (tempRow.delta_customers > 0) {
            new_unique_customers.push(tempRow.delta_customers);
          }

          if (i > num_weeks) {
            tempRow.numCustomers =
              tempRows[i - 1].numCustomers +
              tempRow.delta_customers -
              tempRows[i - (num_weeks + 1)].delta_customers;
            total_num_customers += tempRow.numCustomers;
          } else {
            tempRow.numCustomers =
              tempRows[i - 1].numCustomers + tempRow.delta_customers;
            total_num_customers += tempRow.numCustomers;
          }

          //new loans = tempRow.delta_customers * loan_amount
          tempRow.new_loans = tempRow.delta_customers * loan_amount;

          //fees collected = tempRow.delta_customers * (reg_fee + insurance_fee)
          tempRow.fees_collected = tempRow.delta_customers * total_fees;

          //payment received = tempRows[i - 1].numCustomers * num_weeks*(loan_amount + (loan_amount * (mthly_interest_rate/4)))
          if (include_edtech === "Yes") {
            tempRow.payment_received =
              tempRows[i - 1].numCustomers *
              ((loan_amount +
                num_weeks * (loan_amount * (mthly_interest_rate / 4))) /
                num_weeks +
                (edtech_price * 12) / 52);
          } else {
            tempRow.payment_received =
              tempRows[i - 1].numCustomers *
              ((loan_amount +
                num_weeks * (loan_amount * (mthly_interest_rate / 4))) /
                num_weeks);
          }

          //outstanding = tempRows[i - 1].outstanding - tempRow[i - 1].payment_received
          tempRow.outstanding =
            tempRows[i - 1].outstanding -
            tempRow.payment_received +
            tempRow.delta_customers *
              (loan_amount +
                num_weeks * (loan_amount * (mthly_interest_rate / 4)));

          //amount to invest = tempRow.payment_received + tempRow.fees_collected + tempRows[i - 1].amount_to_invest - tempRow.new_loans
          tempRow.amount_to_invest =
            tempRow.payment_received +
            tempRow.fees_collected +
            tempRows[i - 1].amount_to_invest -
            tempRow.new_loans;

          //market cap = tempRow.outstanding + tempRow.amount_to_invest
          // tempRow.market_cap = tempRow.outstanding + tempRow.amount_to_invest;
          tempRow.market_cap = tempRow.outstanding + tempRow.amount_to_invest;

          //cash remaining = tempRow.amount_to_invest
          tempRow.cash_remaining = tempRow.amount_to_invest;
        }
        tempRows.push(tempRow);
      }
      tempRows[tempRows.length - 1].new_unique_customers = new_unique_customers;
      setRows(tempRows);
      setAverageNumOfCustomers(total_num_customers / tempNumWeeks);
    }
  }, [
    principal,
    numWeeks,
    loan_amount,
    reg_fee,
    total_fees,
    mthly_interest_rate,
    include_edtech,
    edtech_price,
  ]);

  useEffect(() => {
    if (rows.length > 0) {
      let total = 0;
      rows[rows.length - 1].new_unique_customers.forEach((num) => {
        total += num;
      });
      setTotalUniqueCustomers(total);
    }
  }, [rows]);

  useEffect(() => {
    setTotalFees(insurance_fee + reg_fee);
  }, [insurance_fee, reg_fee]);

  useEffect(() => {
    setInsuranceFee(0.02 * loan_amount);
  }, [loan_amount]);

  const sendExchangeRates = (exchangeRatesData) => {
    const method = Object.keys(exchangeRatesData).length > 0 ? "PUT" : "POST";
    fetch("/api/exchangeRates", {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exchangeRatesData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setEditExchangeRatesMode(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formatDate = (date) => {
    const date1 = new Date(date);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = days[date1.getDay()];

    const month = (date1.getMonth() + 1).toString().padStart(2, "0"); // Months are 0 indexed, so add 1
    const day = date1.getDate().toString().padStart(2, "0");
    const year = date1.getFullYear();

    return `${dayName} ${month}-${day}-${year}`;
  };

  const formatTime = (date) => {
    const date1 = new Date(date);
    let hours = date1.getHours().toString().padStart(2, "0");
    const minutes = date1.getMinutes().toString().padStart(2, "0");

    const amOrPm = hours < 12 ? "AM" : "PM";

    if (hours > 12) {
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes} ${amOrPm}`;
  };

  return (
    <>
      <Navbar />
      <div className="fc fdv aic" id="projectionsDiv">
        <div
          className="fc jc aic ProjectionsDivInputs"
          style={{ margin: "20px auto" }}
        >
          <span>
            Principal (<b>GHS</b>): &nbsp;
          </span>
          <input
            type="number"
            name="principal"
            placeholder="principal"
            autoComplete="off"
            onChange={(e) => setPrincipal(e.target.value)}
            value={principal}
            id="principalInput"
          />
        </div>
        <div className="fc fdv jc aic">
          <div className="fc jc aic ProjectionsDivInputs">
            <span>Number of Weeks: &nbsp;</span>
            <input
              type="number"
              name="numWeeks"
              placeholder="number of weeks"
              autoComplete="off"
              onChange={(e) => setNumWeeks(e.target.value)}
              value={numWeeks}
              id="numWeeksInput"
            />
          </div>
          <div
            className="fc jc aic ProjectionsDivInputs"
            style={{ marginTop: "10px" }}
          >
            <span>
              Ave. Loan Amount (<b>GHS</b>): &nbsp;
            </span>
            <input
              type="number"
              name="loan_amount"
              placeholder="loan amount"
              autoComplete="off"
              onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
              value={loan_amount}
              id="loan_amountInput"
            />
          </div>
          <div
            className="fc jc aic ProjectionsDivInputs"
            style={{ marginTop: "10px" }}
          >
            <label htmlFor="edtech">Include Edtech?:&nbsp; </label>
            <select
              name="edtech"
              id="edtech"
              value={include_edtech}
              onChange={(e) => {
                setIncludeEdtech(e.target.value);
              }}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div className="fc w3-padding-32">
            <span>Currency: &nbsp;</span>
            <select
              name="yesNo1"
              id="yesNo1"
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
            >
              <option value="USD">usd</option>
              <option value="CAD">cad</option>
              <option value="EUR">eur</option>
              <option value="GBP">gbp</option>
              <option value="GHS">ghs</option>
            </select>
          </div>
        </div>
        <div className="fc fdv jc aic" style={{ margin: "20px auto" }}>
          {rows.length > 0 && (
            <>
              <div className="fc fdv jc aic">
                <button className="w3-button w3-purple w3-text-white">
                  + Deposit
                </button>
              </div>
              <div className="fc jfe aic" style={{ width: "100%" }}>
                <span>
                  <b>Currency Chosen:</b> &nbsp;
                </span>
                <span className="exchangeRateValue">{currency}</span>
              </div>
              <table className="w3-table-all" style={{ cursor: "pointer" }}>
                <thead>
                  <tr>
                    <th> Week</th>
                    <th> #Customers</th>
                    <th> New Customers</th>
                    <th> New Loans</th>
                    <th> Fees</th>
                    <th> Received</th>
                    <th> Outstanding</th>
                    <th> New Cash</th>
                    <th> Amount to Invest</th>
                    <th> Market Value</th>
                    <th> Cash Rem.</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index + Math.random()}>
                      <td>{index}</td>
                      <td
                        className={
                          index % (num_weeks + 1) === 0 && index !== 0
                            ? "num_of_customers_with_deduction"
                            : "num_of_customers"
                        }
                      >
                        {row.numCustomers}
                      </td>
                      <td
                        className={
                          row.delta_customers > 0 ? "delta_customers" : ""
                        }
                      >
                        {row.delta_customers}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          row.new_loans * exchangeRates[currency]
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          row.fees_collected * exchangeRates[currency]
                        )}
                      </td>
                      <td>
                        {" "}
                        {new Intl.NumberFormat("en-US").format(
                          row.payment_received * exchangeRates[currency]
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          row.outstanding * exchangeRates[currency]
                        )}
                      </td>
                      <td></td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          row.amount_to_invest * exchangeRates[currency]
                        )}
                      </td>
                      <td className="marketValue">
                        {new Intl.NumberFormat("en-US").format(
                          row.market_cap * exchangeRates[currency]
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          row.cash_remaining * exchangeRates[currency]
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {
            <div
              className="fc fdv jc afs exchangeRatesTable w3-card-4"
              style={{
                margin: "20px auto",
                backgroundColor: "#fff",
                padding: "20px",
              }}
            >
              <span>
                Last Updated: <br />
              </span>
              <span className="w3-text-purple">
                {formatDate(exchangeRates.Date)}
              </span>
              <span className="w3-text-blue">
                {formatTime(exchangeRates.Date)}{" "}
              </span>
              <br />
              <div style={{ width: "100%" }} className="fc jc aic">
                Exchange Rates
              </div>
              <br />
              <br />
              <div className="fc jfe aic ratesDiv" style={{ width: "100%" }}>
                {editExchangeRatesMode ? (
                  <div
                    className="fc fdv jfe aic exchangeRateEditTable"
                    style={{ width: "100%" }}
                  >
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <span>USD: &nbsp;</span>
                      <input
                        type="number"
                        name="usd"
                        placeholder="usd"
                        autoComplete="off"
                        onChange={(e) => {
                          dispatch(
                            setExchangeRates({
                              ...exchangeRates,
                              USD: e.target.value,
                              Date: new Date(),
                            })
                          );
                        }}
                        value={exchangeRates.USD}
                        id="usdInput"
                      />
                    </div>
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <span>CAD: &nbsp;</span>
                      <input
                        type="number"
                        name="cad"
                        placeholder="cad"
                        autoComplete="off"
                        onChange={(e) => {
                          dispatch(
                            setExchangeRates({
                              ...exchangeRates,
                              CAD: e.target.value,
                              Date: new Date(),
                            })
                          );
                        }}
                        value={exchangeRates.CAD}
                        id="cadInput"
                      />
                    </div>
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <span>EUR: &nbsp;</span>
                      <input
                        type="number"
                        name="eur"
                        placeholder="eur"
                        autoComplete="off"
                        onChange={(e) => {
                          dispatch(
                            setExchangeRates({
                              ...exchangeRates,
                              EUR: e.target.value,
                              Date: new Date(),
                            })
                          );
                        }}
                        value={exchangeRates.EUR}
                        id="eurInput"
                      />
                    </div>
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <span>GBP: &nbsp;</span>
                      <input
                        type="number"
                        name="gbp"
                        placeholder="gbp"
                        autoComplete="off"
                        onChange={(e) => {
                          dispatch(
                            setExchangeRates({
                              ...exchangeRates,
                              GBP: e.target.value,
                              Date: new Date(),
                            })
                          );
                        }}
                        value={exchangeRates.GBP}
                        id="gbpInput"
                      />
                    </div>
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <button
                        className="w3-button w3-purple w3-text-white"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          sendExchangeRates(exchangeRates);
                          setEditExchangeRatesMode(false);
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="w3-button w3-orange"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          setEditExchangeRatesMode(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="fc jfe aic exchangeRateValueAndLabelsDiv"
                    style={{ width: "100%" }}
                  >
                    <div className="fc jc aic" style={{ width: "100%" }}>
                      <div
                        className="fc jfe aic exchangeRateValueDiv"
                        style={{ width: "100%" }}
                      >
                        <span>USD: &nbsp;</span>
                        <span className="exchangeRateValue">
                          {exchangeRates.USD}
                        </span>
                      </div>
                      <div
                        className="fc jfe aic exchangeRateValueDiv"
                        style={{ width: "100%" }}
                      >
                        <span>CAD: &nbsp;</span>
                        <span className="exchangeRateValue">
                          {exchangeRates.CAD}
                        </span>
                      </div>
                      <div
                        className="fc jfe aic exchangeRateValueDiv"
                        style={{ width: "100%" }}
                      >
                        <span>EUR: &nbsp;</span>
                        <span className="exchangeRateValue">
                          {exchangeRates.EUR}
                        </span>
                      </div>
                      <div
                        className="fc jfe aic exchangeRateValueDiv"
                        style={{ width: "100%" }}
                      >
                        <span>GBP: &nbsp;</span>
                        <span className="exchangeRateValue">
                          {exchangeRates.GBP}
                        </span>
                      </div>
                    </div>
                    <div
                      className="fc jc aic exchangeRateButtonDiv"
                      style={{ width: "100%" }}
                    >
                      <button
                        onClick={() => {
                          setEditExchangeRatesMode(true);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
          <>
            <span>Num of Agents: &nbsp;</span>
            <input
              type="number"
              name="num_of_agents"
              placeholder="num of agents"
              autoComplete="off"
              onChange={(e) => setNumOfAgents(e.target.value)}
              value={num_of_agents}
              id="num_of_agentsInput"
              min={1}
            />
          </>
          {
            <div
              className="fc fdv jc afs w3-card-4"
              style={{
                margin: "20px auto",
                backgroundColor: "lightgray",
                padding: "20px",
              }}
            >
              {rows.length > 0 && (
                <div
                  className="fc fdv jc afs projectionsSummaryDiv"
                  style={{
                    margin: "20px auto",
                    backgroundColor: "lightgray",
                    padding: "20px",
                  }}
                >
                  <h1>{currency}</h1>
                  <span>
                    Principal: {currency}{" "}
                    <span style={{ color: "red" }}>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(principal * exchangeRates[currency])
                      )}
                    </span>{" "}
                  </span>
                  <span style={{ color: "blue" }}>
                    Number of Unique Customers: &nbsp;
                    {total_unique_customers}
                  </span>
                  <span>
                    Average Number of Customers: &nbsp;{" "}
                    {Math.round(average_num_of_customers)}
                  </span>
                  <span>
                    Total Fees collected: {currency}{" "}
                    <span style={{ color: "red" }}>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          total_unique_customers *
                            (reg_fee + insurance_fee) *
                            exchangeRates[currency]
                        )
                      )}
                    </span>{" "}
                  </span>
                  <span>
                    Total Interest Expected: {currency}{" "}
                    <span style={{ color: "red" }}>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          total_unique_customers *
                            total_interest *
                            exchangeRates[currency]
                        )
                      )}
                    </span>{" "}
                  </span>
                  <span>
                    Projected Profit:{" "}
                    <span style={{ color: "green" }}>
                      <b>{currency} </b>{" "}
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          total_unique_customers *
                            (total_interest + total_fees) *
                            exchangeRates[currency]
                        )
                      )}
                    </span>{" "}
                  </span>
                  <span>
                    Ave. Mthly Profit:{" "}
                    <span style={{ color: "blue" }}>
                      <b>{currency} </b>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          4 *
                            Math.round(
                              (total_unique_customers *
                                (total_interest + total_fees)) /
                                numWeeks
                            ) *
                            exchangeRates[currency]
                        )
                      )}
                    </span>{" "}
                  </span>
                  <span>
                    Ave. Weekly Profit:{" "}
                    <span style={{ color: "purple" }}>
                      <b>{currency} </b>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          ((total_unique_customers *
                            (total_interest + total_fees)) /
                            numWeeks) *
                            exchangeRates[currency]
                        )
                      )}
                    </span>{" "}
                  </span>
                  <span>
                    Profit %:{" "}
                    <span style={{ color: "blue" }}>
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          ((total_unique_customers *
                            (total_interest + total_fees)) /
                            principal) *
                            100
                        )
                      )}
                      %
                    </span>{" "}
                  </span>
                  <span style={{ color: "purple" }}>
                    Projected Value: <b>{currency} </b>{" "}
                    {new Intl.NumberFormat("en-US").format(
                      Math.round(
                        (parseInt(principal) +
                          total_unique_customers *
                            (total_interest + total_fees)) *
                          exchangeRates[currency]
                      )
                    )}{" "}
                  </span>
                  <span
                    style={{
                      color: "green",
                      backgroundColor: "#fff",
                      padding: "10px",
                    }}
                  >
                    Projected Value (less Expenses): <b>{currency} </b>{" "}
                    {new Intl.NumberFormat("en-US").format(
                      Math.round(
                        (parseInt(principal) +
                          total_unique_customers *
                            (total_interest + total_fees)) *
                          exchangeRates[currency] -
                          (num_of_agents * weekly_budget * numWeeks +
                            num_of_agents * 250 * numWeeks +
                            (700 / 4) * numWeeks) *
                            exchangeRates[currency]
                      )
                    )}{" "}
                  </span>
                  {include_edtech === "Yes" && (
                    <span
                      style={{
                        color: "blue",
                        backgroundColor: "#fff",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Edtech Revenue: <b>{currency} </b>{" "}
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          average_num_of_customers *
                            edtech_price *
                            12 *
                            exchangeRates[currency]
                        )
                      )}{" "}
                    </span>
                  )}
                </div>
              )}
            </div>
          }
          {rows.length > 0 && (
            <div
              className="fc fdv jc afs projectionsSummaryDiv w3-card-4"
              style={{
                margin: "20px auto",
                backgroundColor: "lightgray",
                padding: "20px 50px",
              }}
            >
              <h3>{currency}</h3>
              <span>
                {" "}
                Number of Agents:{" "}
                <span style={{ color: "purple" }}>{num_of_agents}</span>{" "}
              </span>
              <span>
                {" "}
                Total Salary: {currency}{" "}
                <span style={{ color: "purple" }}>
                  {new Intl.NumberFormat("en-US").format(
                    Math.round(
                      num_of_agents * 250 * numWeeks * exchangeRates[currency]
                    )
                  )}
                </span>{" "}
              </span>
              <span>
                Total Rent: {currency}{" "}
                <span style={{ color: "purple" }}>
                  {new Intl.NumberFormat("en-US").format(
                    Math.round((700 / 4) * numWeeks * exchangeRates[currency])
                  )}
                </span>{" "}
              </span>
              <span>
                Total Budget: {currency}{" "}
                <span style={{ color: "purple" }}>
                  {new Intl.NumberFormat("en-US").format(
                    Math.round(
                      num_of_agents *
                        weekly_budget *
                        numWeeks *
                        exchangeRates[currency]
                    )
                  )}
                </span>
              </span>
              <span
                style={{
                  marginBottom: "20px",
                  marginTop: "5px",
                  backgroundColor: "#fff",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <b>Total Expenses:</b> {currency}{" "}
                <span style={{ color: "red" }}>
                  -
                  {new Intl.NumberFormat("en-US").format(
                    Math.round(
                      (num_of_agents * weekly_budget * numWeeks +
                        num_of_agents * 250 * numWeeks +
                        (700 / 4) * numWeeks) *
                        exchangeRates[currency]
                    )
                  )}
                </span>
              </span>
              <span>
                Fees per Client:{" "}
                <span style={{ color: "blue" }}> &nbsp; GHS {total_fees}</span>
              </span>
              <span>
                Interest per customer:{" "}
                <span style={{ color: "blue" }}>
                  &nbsp; GHS {Math.round(total_interest)}
                </span>
              </span>
              <span>
                Length of Loan:{" "}
                <span style={{ color: "blue" }}>&nbsp; {num_weeks} weeks</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Projections;
