import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setExpenses } from "../../reduxSlices/slices/expenseSlice";
import { displayDateInWords } from "../../utils/utils";

const Expenses = () => {
  const expenses = useSelector((state) => state.expense.expenses.value);
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );
  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );
  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );
  const [currency, setCurrency] = useState("GHS");
  const [showExpenseForm, setShowExpenseForm] = useState(true);
  const [category, setCategory] = useState("miscellaneous_Expenses");
  const [expenseDate, setExpenseDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [expenseId, setExpenseId] = useState("");
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [expenseDescription, setExpenseDescription] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [category_filter, setCategoryFilter] = useState("all");
  const dispatch = useDispatch();

  const saveExpense = async () => {
    const expense = {
      date: expenseDate,
      amount: expenseAmount,
      description: expenseDescription,
      category: category,
      id: inEditMode ? expenseId : uuidv4(),
    };

    const response = await fetch(
      inEditMode ? "/api/expense/" + expense.id : "/api/expense",
      {
        method: inEditMode ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        body: JSON.stringify(expense),
      }
    );

    const data = await response.json();

    if (inEditMode) {
      const newExpenses = expenses.map((expense) => {
        if (expense.id === data.expense.id) {
          return data.expense;
        }
        return expense;
      });
      dispatch(setExpenses(newExpenses));
    } else {
      data.expense.date = new Date(data.expense.date)
        .toISOString()
        .slice(0, 10);
      dispatch(setExpenses([...expenses, data.expense]));
    }
    setCategory("miscellaneous_Expenses");
    setExpenseDate("");
    setExpenseAmount(0);
    setExpenseDescription("");
  };

  const deleteExpense = async (id) => {
    console.log("expenseId", id);
    const response = await fetch("/api/expense/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      const newExpenses = expenses.filter((expense) => {
        return expense._id !== data.expense._id;
      });

      dispatch(setExpenses(newExpenses));
      alert("Expense deleted successfully");
    } else {
      alert("Expense not deleted");
    }
  };

  return (
    <>
      <div className="fc jc aic">
        {!showExpenseForm && (
          <button
            className="collectionAddPaymentButton w3-button w3-green"
            onClick={() => {
              setInEditMode(false);
              setShowExpenseForm(true);
            }}
          >
            Add Expense
          </button>
        )}
      </div>
      {showExpenseForm && (
        <div className="fc fdv aic expenseForm">
          <div className="fc jc aic expenseFormRow">
            <div className="fc fdv jc aic expenseFormInputDiv">
              <label htmlFor="expenseDate">Date</label>
              <input
                type="date"
                id="expenseDate"
                className="expenseInput"
                onChange={(e) => {
                  setExpenseDate(e.target.value);
                }}
                value={expenseDate}
              />
            </div>
            <div className="fc fdv jc aic expenseFormInputDiv">
              <label htmlFor="expenseAmount">Amount</label>
              <input
                type="number"
                className="expenseInput"
                min={0}
                onChange={(e) => {
                  setExpenseAmount(e.target.value);
                }}
                value={expenseAmount}
              />
            </div>
          </div>
          <div className="fc jc aic expenseFormRow">
            <div className="fc fdv jc aic expenseFormInputDiv">
              <label htmlFor="expenseDescription">Description</label>
              <input
                type="text"
                className="expenseInput"
                onChange={(e) => {
                  setExpenseDescription(e.target.value);
                }}
                value={expenseDescription}
              />
            </div>
            <div className="fc fdv jc aic expenseFormInputDiv">
              <label htmlFor="expenseCategorySelect">Category</label>
              <select
                name="expenseCategorySelect"
                id="expenseCategorySelect"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="miscellaneous_Expenses">
                  Miscellaneous Expenses
                </option>
                <option value="salaries_and_wages">Salaries and Wages</option>
                <option value="rent_or_lease">Rent or Lease</option>
                <option value="utilities">Utilities</option>
                <option value="maintenance_and_repairs">
                  Maintenance and Repairs
                </option>
                <option value="travel_and_entertainment">
                  Travel and Entertainment
                </option>
                <option value="insurance">Insurance</option>
                <option value="advertising_and_marketing">
                  Advertising and Marketing
                </option>
                <option value="professional_fees">Professional Fees</option>
                {/**Fees paid for legal, accounting, or consulting services.**/}
                <option value="office_supplies">Office Supplies</option>
                <option value="bank_withdrawal">Bank Withdrawal</option>
                <option value="bank_deposit">Bank Deposit</option>
              </select>
            </div>
          </div>
          <div className="fc jc aic expenseFormRow">
            <button
              className="w3-button w3-blue"
              onClick={() => {
                setShowExpenseForm(false);
                saveExpense();
              }}
            >
              Save
            </button>
            <button
              className="w3-button w3-pink"
              onClick={() => {
                setShowExpenseForm(false);
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="fc w3-padding-32 expensesCurrency">
        <span>Currency: &nbsp;</span>
        <select
          name="yesNo1"
          id="yesNo1"
          value={currency}
          onChange={(e) => {
            setCurrency(e.target.value);
          }}
        >
          <option value="USD">usd</option>
          <option value="CAD">cad</option>
          <option value="EUR">eur</option>
          <option value="GBP">gbp</option>
          <option value="GHS">ghs</option>
        </select>
      </div>
      <div className="fc jc aic">
        <span>Filter by: </span>&nbsp;&nbsp;
        <select
          name="category_filter"
          id="category_filter"
          value={category_filter}
          onChange={(e) => {
            setCategoryFilter(e.target.value);
          }}
        >
          <option value="all">All</option>
          <option value="miscellaneous_Expenses">Miscellaneous Expenses</option>
          <option value="salaries_and_wages">Salaries and Wages</option>
          <option value="rent_or_lease">Rent or Lease</option>
          <option value="utilities">Utilities</option>
          <option value="maintenance_and_repairs">
            Maintenance and Repairs
          </option>
          <option value="travel_and_entertainment">
            Travel And Entertainment
          </option>
          <option value="insurance">Insurance</option>
          <option value="advertising_and_marketing">
            Advertising and Marketing
          </option>
          <option value="professional_fees">Professional Fees</option>
          <option value="office_supplies">Office Supplies</option>
          <option value="bank_withdrawal">Bank Withdrawal</option>
          <option value="bank_deposit">Bank Deposit(+credit)</option>
        </select>
      </div>
      <div className="w3-responsive" style={{ width: "100%" }}>
        <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white expensesTable">
          <thead>
            <tr>
              <th>Date</th>
              <th>id</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Category</th>
              <td>Action</td>
              <td>User</td>
            </tr>
          </thead>
          <tbody>
            {expenses
              .filter((expense) => {
                return category_filter === "all"
                  ? true
                  : expense.category === category_filter;
              })
              .map((expense, index) => {
                return (
                  <tr key={expense._id ? expense._id : index}>
                    <td>{displayDateInWords(expense.date)}</td>
                    <td>{expense.id}</td>
                    <td
                      style={{
                        color:
                          expense.category === "bank_deposit"
                            ? "green"
                            : expense.category === "bank_withdrawal"
                            ? "blue"
                            : "red",
                      }}
                    >
                      {(
                        expense.amount * exchangeRates[currency]
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: currency,
                      })}
                    </td>
                    <td>{expense.description}</td>
                    <td>{expense.category}</td>
                    <td>
                      {security_level === 3 ? (
                        <div className="fc jc aic">
                          <button
                            className="w3-button w3-blue"
                            onClick={() => {
                              setInEditMode(true);
                              setShowExpenseForm(true);
                              setExpenseDate(expense.date);
                              setExpenseAmount(expense.amount);
                              setExpenseDescription(expense.description);
                              setCategory(expense.category);
                              setExpenseId(expense.id);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="w3-button w3-pink"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              deleteExpense(expense.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>{expense.createdBy}</td>
                  </tr>
                );
              })}
            {category_filter === "bank_deposit" && (
              <tr>
                <td>
                  {displayDateInWords(new Date().toISOString().slice(0, 10))}
                </td>
                <td></td>
                <td style={{ color: "red" }}>
                  {(
                    expenses
                      .filter((expense) => {
                        return expense.category === "bank_withdrawal";
                      })
                      .reduce((total, expense) => {
                        return total + expense.amount;
                      }, 0) * exchangeRates[currency]
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                  })}
                </td>
                <td>Total Withdrawal</td>
                <td>bank_withdrawal</td>
                <td></td>
                <td>tamicroloans</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="fc jsa aic expensesTotalDiv w3-brown">
        <span className="expensesTotalSpan">Total</span>
        {category_filter !== "bank_deposit" &&
          category_filter !== "bank_withdrawal" && (
            <span className="expensesTotalSpan">
              {(
                expenses
                  .filter((expense) => {
                    return category_filter === "all"
                      ? true
                      : expense.category === category_filter;
                  })
                  .reduce((total, expense) => {
                    if (expense.category === "bank_deposit")
                      return total - expense.amount;
                    else if (expense.category === "bank_withdrawal")
                      return total + 0;
                    return total + expense.amount;
                  }, 0) * exchangeRates[currency]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: currency,
              })}
            </span>
          )}
        {category_filter === "bank_deposit" && (
          <span className="expensesTotalSpan">
            {(
              expenses
                .filter((expense) => {
                  return (
                    expense.category === "bank_deposit" ||
                    expense.category === "bank_withdrawal"
                  );
                })
                .reduce((total, expense) => {
                  if (expense.category === "bank_withdrawal")
                    return total - expense.amount;
                  return total + expense.amount;
                }, 0) * exchangeRates[currency]
            ).toLocaleString("en-US", {
              style: "currency",
              currency: currency,
            })}
          </span>
        )}
      </div>
    </>
  );
};

export default Expenses;
