import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import useImageLoader from "../../hooks/useImageLoader";
import { parseBoolean, setTokenAndUser } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setAuthToken,
  setAuthUser,
} from "../../reduxSlices/slices/authSlice";
import Spinner from "../../smallComponents/Spinner";
import logo from "../../images/tamicro_loans_logo_svg.svg";
import google_g from "../../images/google_g.png";
import Navbar from "../../smallComponents/navBar/Navbar";

const Register = () => {
  const [opacity, setFinishedLoading] = useImageLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [googleUser, setGoogleUser] = useState({});
  const [googleProfile, setGoogleProfile] = useState({});
  const dispatch = useDispatch();
  const is_authenticated = useSelector((state) =>
    parseBoolean(state.isAuthenticated.auth.value)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (is_authenticated === true || is_authenticated === "true") {
      navigate("/");
    }
  });

  const setAuthenticatication = (bool) => {
    return dispatch(setIsAuthenticated(bool));
  };

  const postRegistration = async (url, data = {}) => {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };

  const registerFormik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirm: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      postRegistration("/api/register", {
        details: {
          userName: values.username,
          password: values.password,
          email: values.email,
        },
      })
        .then((data) => {
          if (data.status === "success") {
            setIsLoading(false);
            setTokenAndUser(
              data.token,
              values.email,
              setAuthToken,
              setAuthUser,
              dispatch
            );
            setAuthenticatication(true);
            navigate("/");
          } else {
            setIsLoading(false);
            setAuthenticatication(false);
            // if (data.redirect && data.redirect === '/login') {
            //   alert("You are already registered; Please login");
            //   navigate('/login');
            // }
          }
        })
        .catch((_err) => {
          setIsLoading(false);
          setAuthenticatication(false);
        });
    },
    validationSchema: Yup.object({
      username: Yup.string(),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol")
        .required("Required"),
      confirm: Yup.string().oneOf(
        [Yup.ref("password"), null],
        'Must match "password" field value'
      ),
    }),
  });

  const clearInput = (val) => {
    registerFormik.values[val] = "";
    registerFormik.setTouched({ val });
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // log out function to log the user out of google and set the profile array to null
  const logOutofGoogle = () => {
    googleLogout();
    setGoogleProfile(null);
  };

  useEffect(() => {
    if (googleUser.access_token) {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${googleUser.access_token}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setGoogleProfile(res);
        })
        .catch((err) => console.log(err));
    }
  }, [googleUser]);

  useEffect(() => {
    const setAuthenticatication = (bool) => {
      return dispatch(setIsAuthenticated(bool));
    };

    const registerOrLoginWithGoogle = (profile) => {
      const url = "/api/registerOrLoginWithGoogle";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAuthenticatication("true");
            setTokenAndUser(
              data.token,
              data.email,
              setAuthToken,
              setAuthUser,
              dispatch
            );
            navigate("/");
          } else {
            setAuthenticatication("false");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (googleProfile && googleProfile.name && googleProfile.email) {
      const profile = {
        name: googleProfile.name,
        email: googleProfile.email,
      };
      registerOrLoginWithGoogle(profile);
      logOutofGoogle();
    }
  }, [googleProfile, dispatch, navigate]);

  return (
    <>
      <Navbar />
      <div className="fc fdv aic jc loginOrRegisterPage">
        <div className="fc fdv jc aic loginPageMainDiv" id="loginPageMainDiv">
          <form
            className="fc fdv aic"
            id="cardRegister"
            onSubmit={registerFormik.handleSubmit}
          >
            <div className="fc jc aic loginCardHeader" id="loginCardHeader">
              <span className="material-symbols-sharp">
                enhanced_encryption
              </span>
              <span>Signup for Tamicroloans Inc.</span>
            </div>
            <div className="fc jc aic cardLoginImageDiv">
              <img
                className="w3-image w3-animate-zoom loginImage"
                src={logo}
                alt="login"
                onLoad={() => {
                  setFinishedLoading();
                }}
                style={{ opacity: opacity }}
              />
            </div>
            <div className="fc fdv aic" id="registerInputs">
              <div className="fc jfe aic registerInputsDiv">
                <input
                  type="text"
                  name="username"
                  placeholder="username (optional)"
                  autoComplete="off"
                  onChange={registerFormik.handleChange}
                  onBlur={registerFormik.handleBlur}
                  value={registerFormik.values.username}
                  disabled
                />
                <div className="fc aic jc backspaceDiv">
                  {registerFormik.values.username && (
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "16px",
                        color: "#3AA9E8",
                      }}
                      onClick={() => clearInput("username")}
                    >
                      refresh
                    </span>
                  )}
                </div>
              </div>
              <div className="formikOuterErrorDiv">
                {registerFormik.touched.username &&
                registerFormik.errors.username ? (
                  <div style={{ color: "red" }} className="formikErrorDiv">
                    {registerFormik.errors.username}
                  </div>
                ) : null}
              </div>
              <div className="fc jfe aic registerInputsDiv">
                <input
                  type="email"
                  name="email"
                  placeholder="email@email.com"
                  autoComplete="off"
                  onChange={registerFormik.handleChange}
                  onBlur={registerFormik.handleBlur}
                  value={registerFormik.values.email}
                  disabled
                />
                <div className="fc aic jc backspaceDiv">
                  {registerFormik.values.email && (
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "16px",
                        color: "#3AA9E8",
                      }}
                      onClick={() => clearInput("email")}
                    >
                      refresh
                    </span>
                  )}
                </div>
              </div>
              <div className="fc jc aic formikOuterErrorDiv">
                {registerFormik.touched.email && registerFormik.errors.email ? (
                  <div style={{ color: "red" }} className="formikErrorDiv">
                    {registerFormik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="fc jfe aic registerInputsDiv">
                <input
                  type="password"
                  name="password"
                  placeholder="password"
                  autoComplete="off"
                  onChange={registerFormik.handleChange}
                  onBlur={registerFormik.handleBlur}
                  value={registerFormik.values.password}
                  disabled
                />
                <div className="fc aic jc backspaceDiv">
                  {registerFormik.values.password && (
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "16px",
                        color: "#3AA9E8",
                      }}
                      onClick={() => clearInput("password")}
                    >
                      refresh
                    </span>
                  )}
                </div>
              </div>
              <div className="fc jc aic formikOuterErrorDiv">
                {registerFormik.touched.password &&
                registerFormik.errors.password ? (
                  <div style={{ color: "red" }} className="formikErrorDiv">
                    {registerFormik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="fc jfe aic registerInputsDiv">
                <input
                  type="password"
                  name="confirm"
                  placeholder="confirm password"
                  autoComplete="off"
                  onChange={registerFormik.handleChange}
                  onBlur={registerFormik.handleBlur}
                  value={registerFormik.values.confirm}
                  disabled
                />
                <div className="fc aic jc backspaceDiv">
                  {registerFormik.values.confirm && (
                    <span
                      className="material-symbols-outlined"
                      style={{
                        fontSize: "16px",
                        color: "#3AA9E8",
                      }}
                      onClick={() => clearInput("confirm")}
                    >
                      refresh
                    </span>
                  )}
                </div>
              </div>
              <div className="fc jc aic formikOuterErrorDiv">
                {registerFormik.touched.confirm &&
                registerFormik.errors.confirm ? (
                  <div style={{ color: "red" }} className="formikErrorDiv">
                    {registerFormik.errors.confirm}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="fc jc aic loginCardButtonDiv">
              <button
                className="w3-button w3-card w3-round-xxlarge registerCardButton"
                id="registerCardButton"
                disabled
              >
                Submit
              </button>
            </div>
            <div className="switchLoginAndRegister">
              Already a Member?{" "}
              <Link to="/login" className="w3-text-blue" id="signInLink">
                Sign In
              </Link>
            </div>
          </form>
          <div
            className="fc aic jc loginCardFooter w3-card"
            id="loginCardFooter"
          >
            <button
              className="w3-button"
              id="loginWithGoogleButton"
              onClick={loginWithGoogle}
            >
              <img
                className="w3-image w3-animate-zoom google_g"
                src={google_g}
                alt="google_g"
              />
              Signup with Google
            </button>
          </div>
        </div>
        {isLoading && <Spinner displayText="Registering" />}
      </div>
    </>
  );
};

export default Register;
