import React from "react";
import Navbar from "../../smallComponents/navBar/Navbar";
import HomeCard from "../../smallComponents/homeCard/HomeCard";

const Home = () => {
  return (
    <div className="fc fdv aic homeMainDiv">
      <Navbar />
      <div className="fc fdv aic jc homeDiv">
        <HomeCard />
      </div>
    </div>
  );
};

export default Home;
