import { createSlice } from '@reduxjs/toolkit'

export const applicationModeSlice = createSlice({
    name: 'applicationMode',
    initialState: {
        applicationMode: {
            value: {
                mode: 'new_application',// enum: ['new_applicant', 'new_application', 'existing_application']
            }
        },
    },
    reducers: {
        setApplicationMode: (state, applicationMode = '') => {
            if (state.applicationMode.value !== applicationMode.payload) {
                state.applicationMode.value = applicationMode.payload
                localStorage.setItem('applicationMode', state.applicationMode.value)
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { setApplicationMode } = applicationModeSlice.actions

export default applicationModeSlice.reducer