import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  addMonthsToCurrentDate,
  ratesObject,
  computeInvestmentAccountValueToDate,
} from "./investmentUtils";
import { setInvestmentAccounts } from "../../reduxSlices/slices/investmentAccountSlice";
import { displayDateInWords } from "../../utils/utils";

const InvestmentAccounts = () => {
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );

  const investment_accounts = useSelector(
    (state) => state.investmentAccount.investmentAccounts.value
  );

  const exchangeRates = useSelector(
    (state) => state.exchangeRates.exchangeRates.value
  );

  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );

  const dispatch = useDispatch();

  const [ghana_card_id, setGhana_card_id] = useState("");
  const [last_name, setLast_name] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [openingDate, setOpeningDate] = useState("");
  const [period, setPeriod] = useState(0);
  const [amount, setAmount] = useState(0);
  const [inEditMode, setInEditMode] = useState(false);
  const [showInvestmentForm, setShowInvestmentForm] = useState(false);
  const [currency, setCurrency] = useState("GHS");

  const saveInvestmentAccount = async () => {
    const maturityDate = addMonthsToCurrentDate(openingDate, period)
      .toISOString()
      .slice(0, 10);

    const investmentAccount = {
      ghana_card_id: ghana_card_id,
      last_name: last_name,
      first_name: first_name,
      account_number: uuidv4(),
      openingDate: openingDate,
      period: period,
      maturityDate: maturityDate,
      amount: amount,
      rate: ratesObject[period],
      status: "active",
    };

    const response = await fetch(
      inEditMode
        ? "/api/investmentAccount/" + investmentAccount.account_number
        : "/api/investmentAccount",
      {
        method: inEditMode ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `Bearer xxyzty ${auth_token}`,
        },
        body: JSON.stringify(investmentAccount),
      }
    );

    const data = await response.json();

    if (inEditMode) {
      const newInvestmentAccounts = investment_accounts.map((account) => {
        if (account.account_number === data.investmentAccount.account_number) {
          return data.investmentAccount;
        }
        return newInvestmentAccounts;
      });
      dispatch(setInvestmentAccounts(newInvestmentAccounts));
      setShowInvestmentForm(false);
      alert("Investment Account Updated Successfully");
    } else {
      dispatch(
        setInvestmentAccounts([...investment_accounts, data.investmentAccount])
      );
      setShowInvestmentForm(false);
      alert("Investment Account Created Successfully");
    }
  };

  const deleteInvestmentAccount = async (account_number) => {
    const response = await fetch("/api/investmentAccount/" + account_number, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
    });

    const data = await response.json();

    const newInvestmentAccounts = investment_accounts.filter((account) => {
      return account.account_number !== data.investmentAccount.account_number;
    });

    dispatch(setInvestmentAccounts(newInvestmentAccounts));
  };

  return (
    <>
      <div className="fc fdv jc aic investmentsAccountPage">
        <div className="fc w3-padding-32">
          <span>Currency: &nbsp;</span>
          <select
            name="yesNo1"
            id="yesNo1"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option value="USD">usd</option>
            <option value="CAD">cad</option>
            <option value="EUR">eur</option>
            <option value="GBP">gbp</option>
            <option value="GHS">ghs</option>
          </select>
        </div>
        <button
          className="w3-button w3-green w3-round-large fc jc aic"
          style={{ marginTop: "50px" }}
          onClick={() => {
            setShowInvestmentForm(!showInvestmentForm);
          }}
        >
          <span className="material-symbols-sharp">add</span>
          <span>Account</span>
        </button>
        {showInvestmentForm && (
          <div className="fc fdv jc aic investmentAccountInputsDiv w3-padding-32">
            <div className="fc jsa aic investmentAccounNameInputs">
              <div className="fc fdv jc afs">
                <label htmlFor="ghana_card_id">Client ID</label>
                <input
                  type="text"
                  name="ghana_card_id"
                  id="ghana_card_id"
                  onChange={(e) => {
                    setGhana_card_id(e.target.value);
                  }}
                  value={ghana_card_id}
                />
              </div>

              <div className="fc fdv jc afs">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  onChange={(e) => {
                    setLast_name(e.target.value);
                  }}
                  value={last_name}
                />
              </div>

              <div className="fc fdv jc afs">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={(e) => {
                    setFirst_name(e.target.value);
                  }}
                  value={first_name}
                />
              </div>
            </div>

            <div className="fc jsa aic investmentAccounDataInputs">
              <div className="fc fdv jc afs">
                <label htmlFor="openingDate">Opening Date</label>
                <input
                  type="date"
                  name="openingDate"
                  id="openingDate"
                  onChange={(e) => {
                    setOpeningDate(e.target.value);
                  }}
                  value={openingDate}
                />
              </div>

              <div className="fc fdv jc afs">
                <label htmlFor="period">Period</label>
                <select
                  className="w3-select investmentsPredictionDropdown"
                  name="investmentsPredictionDropdown"
                  onChange={(e) => {
                    parseInt(setPeriod(e.target.value));
                  }}
                  style={{ border: "1px solid #ccc" }}
                  value={period}
                >
                  <option value="" disabled>
                    Length of Investment
                  </option>
                  <option value="1">1 month</option>
                  <option value="6">6 months</option>
                  <option value="12">12 months</option>
                  <option value="18">18 months</option>
                  <option value="24">24 months</option>
                </select>
              </div>

              <div className="fc fdv jc afs">
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  value={amount}
                />
              </div>
            </div>

            <div className="fc jc aic investmentsInputTableButtonsDiv">
              <button
                className="w3-button w3-yellow w3-round-large fc jc aic"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  saveInvestmentAccount();
                }}
                disabled={security_level !== 3}
              >
                <span>Submit</span>
              </button>
              <button
                className="w3-button w3-pink w3-round-large fc jc aic"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setInEditMode(false);
                  setGhana_card_id("");
                  setLast_name("");
                  setFirst_name("");
                  setOpeningDate("");
                  setPeriod(0);
                  setAmount(0);
                  setShowInvestmentForm(false);
                }}
                disabled={security_level !== 3}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        )}
        {investment_accounts.length >= 0 && (
          <div className="w3-responsive investmentAccountsTableDiv">
            <table className="w3-table w3-striped w3-hoverable investmentAccountsTable">
              <thead>
                <tr className="w3-light-grey">
                  <th scope="col">
                    <span>ID</span>
                  </th>
                  <th scope="col">
                    <span>Last Name</span>
                  </th>
                  <th scope="col">
                    <span>First Name</span>
                  </th>
                  <th scope="col">
                    <span>Start</span>
                  </th>
                  <th scope="col">
                    <span>Period</span>
                  </th>
                  <th scope="col">
                    <span>Close</span>
                  </th>
                  <th scope="col">
                    <span>Amount(mths)</span>
                  </th>
                  <th scope="col">
                    <span>Rate</span>
                  </th>
                  <th scope="col">
                    <span>Interest Earned</span>
                  </th>
                  <th scope="col">
                    <span>Value (P + I)</span>
                  </th>
                  <th scope="col">
                    <span>Status</span>
                  </th>
                  <th scope="col">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody id="investmentsTableBody" style={{ textAlign: "center" }}>
                {investment_accounts.map((account, index) => {
                  return (
                    <tr key={index} className="w3-card-4 investmentAccountDiv">
                      <td>{account.ghana_card_id}</td>
                      <td>{account.last_name}</td>
                      <td>{account.first_name}</td>
                      <td>
                        {displayDateInWords(
                          new Date(account.openingDate).toISOString()
                        )}
                      </td>
                      <td>{account.period}</td>
                      <td>
                        {new Date(account.maturityDate)
                          .toISOString()
                          .slice(0, 10)}
                      </td>
                      <td>
                        {(
                          account.amount * exchangeRates[currency]
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: currency,
                        })}
                      </td>
                      <td>{account.rate}%</td>
                      <td>
                        {(
                          (computeInvestmentAccountValueToDate(
                            account.amount,
                            ratesObject[account.period],
                            new Date(account.openingDate),
                            new Date(
                              addMonthsToCurrentDate(
                                account.openingDate,
                                account.period
                              )
                                .toISOString()
                                .slice(0, 10)
                            )
                          ) -
                            account.amount) *
                          exchangeRates[currency]
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: currency,
                        })}
                      </td>
                      <td>
                        {(
                          computeInvestmentAccountValueToDate(
                            account.amount,
                            ratesObject[account.period],
                            new Date(account.openingDate),
                            new Date(
                              addMonthsToCurrentDate(
                                account.openingDate,
                                account.period
                              )
                                .toISOString()
                                .slice(0, 10)
                            )
                          ) * exchangeRates[currency]
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: currency,
                        })}
                      </td>
                      <td>{account.status}</td>
                      <td>
                        <div className="fc jc aic">
                          <button
                            className="w3-button w3-green w3-round-large fc jc aic"
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                              setInEditMode(true);
                              setGhana_card_id(account.ghana_card_id);
                              setLast_name(account.last_name);
                              setFirst_name(account.first_name);
                              setOpeningDate(account.openingDate);
                              setPeriod(account.period);
                              setAmount(account.amount);
                            }}
                            disabled={security_level !== 3}
                          >
                            <span className="material-symbols-sharp">edit</span>
                          </button>
                          <button
                            className="w3-button w3-red w3-round-large fc jc aic"
                            onClick={() => {
                              deleteInvestmentAccount(account.account_number);
                            }}
                            disabled={security_level !== 3}
                          >
                            <span className="material-symbols-sharp">
                              delete
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default InvestmentAccounts;
