import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCustomer,
  getCollectionForLoanIdOnDate,
  getLoanAmount,
  getTotalCollectionsForLoanIdFromTo,
  getTotalCollectionsOverPeriod,
} from "./reportsUtilFunctions";
import { getArrayTotal, displayDateInWords } from "../../utils/utils";

const FiveDayCollections = () => {
  const collections = useSelector(
    (state) => state.collection.collections.value
  );
  const customers = useSelector((state) => state.customer.customers.value);

  const [filteredAndSortedCollections, setFilteredAndSortedCollections] =
    useState(collections);

  const [
    filteredAndSortedWithStatusCollections,
    setFilteredAndSortedWithStatusCollections,
  ] = useState(collections);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState("");
  const [dateColumns, setDateColumns] = useState([]); // ["2021-01-01", "2021-01-02", "2021-01-03"
  const [filteredCustomers, setFilteredCustomers] = useState("all");
  const [collectionsStatus, setCollectionsStatus] = useState("All"); // ["All", "Open", "Done"]

  useEffect(() => {
    const filteredCollections = filteredAndSortedCollections.filter(
      (collection) => {
        if (collectionsStatus === "All") {
          return collection;
        }
        if (collectionsStatus === "Open") {
          return (
            getLoanAmount(customers, collection.loan_id) -
              getArrayTotal(collection.payments) >
            0
          );
        }
        if (collectionsStatus === "Done") {
          return (
            getLoanAmount(customers, collection.loan_id) -
              getArrayTotal(collection.payments) <=
            0
          );
        }
        return collection;
      }
    );

    setFilteredAndSortedWithStatusCollections(filteredCollections);
  }, [collectionsStatus, filteredAndSortedCollections, customers]);

  useEffect(() => {
    const getDateColumns = () => {
      // array of dates from fromDate to 5 days later
      const dates = [];
      const startDate =
        fromDate === "" ? new Date("2023-07-05") : new Date(fromDate);

      for (let i = 0; i < 5; i++) {
        // 1 week
        const date = new Date(startDate.getTime() + i * 86400000);
        dates.push(date.toISOString().slice(0, 10));
      }

      setDateColumns(dates);
    };

    getDateColumns();
  }, [fromDate, toDate]);

  const updateSortAndFilter = () => {
    let filtered = "";

    if (filteredCustomers !== "all") {
      filtered = collections.filter(
        (collection) => collection.loan_id === filteredCustomers
      );
    }

    setFilteredAndSortedCollections(filtered);
  };

  return (
    <div className="w3-responsive">
      <div className="fc jc aic reportsCollectionsTableHeader">
        <div className="fc reportCollectionsFilterDates">
          <div className="fc fdv aic reportCollectionsFilterInputs">
            <span className="reportSpan">Start Date</span>
            <input
              type="date"
              className="w3-input reportInput"
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              value={fromDate}
            />
          </div>
        </div>
        <div className="fc fdv aic reportCollectionsFilterInputs reportCollectionsFilterClientInput">
          <select
            className="w3-select reportInput"
            name="option"
            onChange={(e) => {
              setFilteredCustomers(e.target.value);
            }}
            defaultValue={""}
          >
            <option disabled value="">
              Choose a client
            </option>
            <option value="all">All</option>
            {customers.map((customer, index) => (
              <option key={index} value={customer.loan_id}>
                {customer.first_name} {customer.last_name}: {customer.loan_id}
              </option>
            ))}
          </select>
        </div>
        <div className="fc reportsCollectionsTableHeaderButtons">
          <div className="fc fdv aic reportCollectionsFilterInputs">
            <button
              className="w3-button w3-green reportInput reportCollectionsFilterButton"
              onClick={() => {
                updateSortAndFilter();
              }}
            >
              Submit
            </button>
          </div>
          <div className="fc fdv aic reportCollectionsFilterInputs">
            <button
              className="w3-button w3-blue reportInput reportCollectionsFilterButton"
              onClick={() => {
                setFromDate("");
                setToDate("");
                setFilteredCustomers("all");
                setFilteredAndSortedCollections(collections);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="fc jc aic w3-responsive">
        <table className="w3-table-all collectionsReportTable w3-responsive">
          <thead className="collectionsReportHead">
            <tr>
              <th className="reportHeader">#</th>
              <th className="reportHeader" onClick={() => {}}>
                Loan ID
              </th>
              <th className="reportHeader">First Name</th>
              <th className="reportHeader">Last Name</th>
              <th className="reportHeader">Collected</th>
              <th className="reportHeader">Remaining</th>
              <th className="reportHeader">Last Update</th>
              <th className="reportHeader">
                <select
                  name="status"
                  id="collectionsReportStatusSelect"
                  value={collectionsStatus}
                  onChange={(e) => {
                    setCollectionsStatus(e.target.value);
                  }}
                >
                  <option value="All">All</option>
                  <option value="Open">Open</option>
                  <option value="Done">Done</option>
                </select>
              </th>
              {dateColumns.map((date, index) => (
                <th key={index} className="reportHeader">
                  {date}
                </th>
              ))}
              <th className="reportHeader">Total (GHS)</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedWithStatusCollections.map((collection, index) => (
              <tr key={index} className="collectionsReportTableRow">
                <td className="reportData">{index + 1}</td>
                <td className="reportData">{collection.loan_id}</td>
                <td className="reportData">
                  {getCustomer(customers, collection.loan_id)?.first_name}{" "}
                </td>
                <td className="reportData">
                  {getCustomer(customers, collection.loan_id)?.last_name}
                </td>
                <td className="reportData">
                  {getArrayTotal(collection.payments)}
                </td>
                <td className="reportData">
                  {getLoanAmount(customers, collection.loan_id) -
                    getArrayTotal(collection.payments)}
                </td>
                <td className="reportData">
                  {displayDateInWords(collection.updatedAt)}
                </td>
                <td className="reportData">
                  {getLoanAmount(customers, collection.loan_id) -
                    getArrayTotal(collection.payments) <=
                  0 ? (
                    <span className="reportDataSpan w3-red w3-padding">
                      Done
                    </span>
                  ) : (
                    <span className="reportDataSpan w3-green w3-padding">
                      Open
                    </span>
                  )}
                </td>
                {dateColumns.map((date, index) => (
                  <td key={index} className="reportData">
                    {getCollectionForLoanIdOnDate(
                      collections,
                      collection.loan_id,
                      date
                    )}
                  </td>
                ))}
                <td className="reportData w3-text-green">
                  {getTotalCollectionsForLoanIdFromTo(
                    collections,
                    collection.loan_id,
                    dateColumns[0],
                    dateColumns[dateColumns.length - 1]
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot id="collectionsReportFooter">
            <tr id="collectionsReportFinalRow">
              <td className="reportData">Total</td>
              <td
                className="reportData w3-blue"
                colSpan={13}
                style={{ textAlign: "end" }}
              >
                {getTotalCollectionsOverPeriod(
                  filteredAndSortedWithStatusCollections,
                  dateColumns[0],
                  dateColumns[dateColumns.length - 1]
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default FiveDayCollections;
