import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCustomer } from "../reduxSlices/slices/customerSlice";
import {
  setCollection,
  setCollections,
} from "../reduxSlices/slices/collectionSlice";
import Navbar from "../smallComponents/navBar/Navbar";

const Collections = () => {
  const [loanId, setLoanId] = useState("");
  const [newPayment, setNewPayment] = useState({});
  const [sortedCustomers, setSortedCustomers] = useState([]); // [1]
  const [latestCollection, setLatestCollection] = useState({}); // {updated_by: "email@email_domain.com", updated_at: "2021-08-12T12:00:00.000Z"}
  const [latestUpdatedCustomer, setLatestUpdatedCustomer] = useState({
    last_name: "",
    first_name: "",
  }); //
  const auth_token = useSelector(
    (state) => state.isAuthenticated.auth_token.value
  );
  const customers = useSelector((state) => state.customer.customers.value);
  const customer = useSelector((state) => state.customer.customer.value);
  const collections = useSelector(
    (state) => state.collection.collections.value
  );
  const collection = useSelector((state) => state.collection.collection.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const customer = customers.find((customer) => customer.loan_id === loanId);
    dispatch(setCustomer(customer));

    const currentCollection = collections.find(
      (collection) => collection.loan_id === loanId
    );

    if (currentCollection) {
      dispatch(setCollection(currentCollection));
    } else {
      dispatch(
        setCollection({
          loan_id: loanId,
          payments: [],
        })
      );
    }
  }, [collections, customers, dispatch, loanId]);

  const saveAndUpdateCollections = async () => {
    const newCollection = {
      loan_id: loanId,
      payments: [...collection.payments, newPayment],
    };

    const method = collections.find(
      (collection) => collection.loan_id === loanId
    )
      ? "PUT"
      : "POST";

    const filteredCollections = collections.filter(
      (collection) => collection.loan_id !== loanId
    );

    const updatedCollections = [...filteredCollections, newCollection];

    const response = await fetch("/api/collection", {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer xxyzty ${auth_token}`,
      },
      body: JSON.stringify(newCollection),
    });

    const data = await response.json();

    if (response.status === 403 || response.status === 401) {
      alert("error saving client: try logging in again");
      navigate("/login");
    } else if (response.status === 200) {
      dispatch(setCollections(updatedCollections));
      setNewPayment({});
      alert(data.message);
    }
  };

  useEffect(() => {
    const sorted = [...customers].sort((a, b) => {
      if (a.first_name < b.first_name) {
        return -1;
      } else if (a.first_name > b.first_name) {
        return 1;
      } else {
        return 0;
      }
    });

    setSortedCustomers(sorted);
  }, [customers]);

  useEffect(() => {
    // sort collections by date
    const sortedCollections = [...collections].sort((a, b) => {
      if (a.updatedAt < b.updatedAt) {
        return -1;
      } else if (a.updatedAt > b.updatedAt) {
        return 1;
      } else {
        return 0;
      }
    });

    const lastCollection = sortedCollections[sortedCollections.length - 1];
    const lastUpdatedLoanId = lastCollection?.loan_id;
    const lastUpdatedCustomer = customers.find(
      (customer) => customer.loan_id === lastUpdatedLoanId
    );

    setLatestCollection(lastCollection);
    setLatestUpdatedCustomer(lastUpdatedCustomer);
  }, [collections, customers]);

  return (
    <>
      <Navbar />
      <div className="fc fdv aic collectionsDiv">
        <h1>Collections</h1>
        <select
          className="w3-select collectionSelect"
          name="option"
          onChange={(e) => {
            setLoanId(e.target.value);
          }}
        >
          <option>Choose a client</option>
          {sortedCustomers.map((customer, index) => (
            <option key={index} value={customer.loan_id}>
              {customer.first_name} {customer.last_name}: {customer.loan_id}
            </option>
          ))}
        </select>
        <span className="fc jc aic collectionHeader">
          {customer?.first_name} {customer?.last_name} {customer?.loan_id}
        </span>
        <div className="fc fdv aic collectionDiv2">
          <table className="w3-table collectionTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {collection?.payments.map((payment, index) => (
                <tr key={index} className="w3-card collectionDiv3">
                  <td className="collectionTd">{payment.date.slice(0, 10)}</td>
                  <td className="collectionTd">
                    <span className="fc jc aic w3-text-green">
                      {payment.amount} GHS
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="fc fdv aic collectionDiv4">
          {Object.keys(newPayment).length > 0 && (
            <div className="collectionTable">
              <div className="fc jc aic collectionsDateAndAmountInputDiv">
                <div className="fc fdv jc aic collectionDateDiv">
                  <label htmlFor="collectionDate">Date</label>
                  <input
                    type="date"
                    id="collectionDate"
                    className="collectionInput"
                    onChange={(e) => {
                      setNewPayment({
                        ...newPayment,
                        date: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="fc fdv jc aic collectionsAmountDiv">
                  <label htmlFor="collectionAmount">Amount</label>
                  <input
                    type="number"
                    className="collectionInput"
                    onChange={(e) => {
                      setNewPayment({
                        ...newPayment,
                        amount: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="fc jc aic collectionsButtonsDiv">
                <button
                  className="collectionButton w3-button w3-green"
                  onClick={() => {
                    saveAndUpdateCollections();
                  }}
                >
                  Save
                </button>
                <button
                  className="collectionButton w3-button w3-red"
                  onClick={() => {
                    setNewPayment({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="fc jc aic w3-yellow w3-text-blue w3-margin customerCollectionsTotalDiv">
          Total Collected:{" "}
          {collection
            ? collection.payments.reduce((a, b) => a + Number(b.amount), 0)
            : 0}{" "}
          GHS
        </div>
        <div className="fc jc aic customerCollectionsRemainingDiv">
          Remaining:{" "}
          {customer && collection
            ? Number(customer.loan_amount_ghs) *
                (1 + Number(customer.loan_interest_rate) / 100) -
              collection.payments.reduce((a, b) => a + Number(b.amount), 0)
            : 0}{" "}
          GHS{" "}
        </div>
        <div className="fc jc aic">
          <button
            className="collectionAddPaymentButton w3-button w3-green"
            onClick={() => {
              setNewPayment({
                date: "",
                amount: "",
              });
            }}
            disabled={loanId === ""}
          >
            Add Payment
          </button>
        </div>
        <div className="fc jc aic collectionTimeStamp w3-card">
          {latestCollection?.updatedAt && (
            <div className="fc fdv jc aic">
              <span>
                Last updated by: &nbsp; <b>{latestCollection.created_by}</b>
              </span>
              <span>
                on: &nbsp; <b>{latestCollection.updatedAt.slice(0, 10)}</b>
              </span>
              <span>
                for loan Id: &nbsp;<b>{latestCollection.loan_id}</b>
              </span>
              <span>
                Customer First Name: <b>{latestUpdatedCustomer?.first_name}</b>{" "}
              </span>
              <span>
                Customer Last Name: <b>{latestUpdatedCustomer?.last_name}</b>{" "}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Collections;
