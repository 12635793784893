import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setAuthToken,
} from "../../reduxSlices/slices/authSlice";
import { setApplicationMode } from "../../reduxSlices/slices/applicationModeSlice";
import { parseBoolean } from "../../utils/utils";
import logo from "../../images/tamicro_loans_full_logo_svg.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const is_authenticated = useSelector((state) =>
    parseBoolean(state.isAuthenticated.auth.value)
  );

  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );

  const handleLogout = async () => {
    const response = await fetch("/api/logout", {
      method: "POST",
      credentials: "include", // Needed to include the cookie
    });
    if (response.ok) {
      // Handle successful logout here. For example, redirect to the login page.
      console.log("Logged out successfully");
    } else {
      console.error("Logout failed");
    }

    dispatch(setIsAuthenticated("false"));
    dispatch(setAuthToken(null));
    navigate("/");
  };

  const toggleNavBarDropdown = () => {
    const navBarDropdownDiv = document.getElementById("navBarDropdownDiv");
    if (navBarDropdownDiv.style.display === "none") {
      navBarDropdownDiv.style.display = "flex";
    } else {
      navBarDropdownDiv.style.display = "none";
    }
  };

  return (
    <div className="fc aic jsb navBar">
      <div className="fc jc aic">
        <img
          className="w3-image"
          src={logo}
          alt="logo"
          id="navBarLogo"
          onClick={() => {
            navigate("/");
          }}
        />
        <button
          className="w3-button  w3-hover-none w3-hover-text-grey fc jc aic"
          id="navBarLoginButton"
          onClick={() => {
            if (!is_authenticated) {
              navigate("/login");
            } else {
              handleLogout();
            }
          }}
        >
          {is_authenticated ? (
            <span className="material-symbols-sharp">login</span>
          ) : (
            <span className="material-symbols-sharp">logout</span>
          )}
          {is_authenticated && <span>&nbsp;Logout</span>}
          {!is_authenticated && <span>&nbsp;Login</span>}
        </button>
      </div>
      <div className="fc fdv aic">
        <span
          className="material-symbols-sharp"
          id="navBarMenuIcon"
          onClick={() => {
            if (!is_authenticated) {
              navigate("/login");
            } else {
              toggleNavBarDropdown();
            }
          }}
        >
          menu
        </span>
        <div
          className="fc fdv aic jsa w3-animate-zoom"
          id="navBarDropdownDiv"
          style={{ display: "none" }}
        >
          <button
            className="fc jc aic navBarDropdownSpan"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </button>
          {parseInt(security_level) >= 2 && (
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  dispatch(setApplicationMode("existing_application"));
                  navigate("/clients");
                }
              }}
            >
              Clients
            </button>
           )} 
          {parseInt(security_level) >= 1 && (
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  navigate("/collections");
                }
              }}
            >
              Collections
            </button>
          )}
          {
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  if (parseInt(security_level) < 2) {
                    alert("You are not authorized to view this page");
                  } else {
                    navigate("/reports");
                  }
                }
              }}
            >
              Reports
            </button>
          }
          {parseInt(security_level) >= 2 && (
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  navigate("/projections");
                }
              }}
            >
              Projections
            </button>
          )}
          {parseInt(security_level) >= 1 && (
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  navigate("/investments");
                }
              }}
            >
              Investments
            </button>
          )}
          {parseInt(security_level) === 3 && (
            <button
              className="fc jc aic navBarDropdownSpan"
              onClick={() => {
                if (!is_authenticated) {
                  navigate("/login");
                } else {
                  navigate("/approvedUsers");
                }
              }}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Authorized Users
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
