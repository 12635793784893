import { createSlice } from "@reduxjs/toolkit";

export const paymentScheduleSlice = createSlice({
  name: "paymentSchedule",
  initialState: {
    paymentSchedule: {
      value: {
        clientEmail: "",
        formNumber: "",
        dateOfApplication: "",
        loanStartDate: "",
        loanAmount: 0,
        interestRateWeekly: 0,
        loanTenure: 0,
      },
    }, //
    paymentSchedules: { value: [] },
  },
  reducers: {
    setPaymentSchedule: (state, paymentSchedule = {}) => {
      if (state.paymentSchedule.value !== paymentSchedule.payload) {
        state.paymentSchedule.value = paymentSchedule.payload;
      }
    },
    setPaymentSchedules: (state, paymentSchedules = []) => {
      if (
        JSON.stringify(state.paymentSchedules.value) !==
        JSON.stringify(paymentSchedules.payload)
      ) {
        state.paymentSchedules.value = paymentSchedules.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPaymentSchedule, setPaymentSchedules } =
  paymentScheduleSlice.actions;

export default paymentScheduleSlice.reducer;
