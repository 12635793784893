import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../smallComponents/navBar/Navbar";
import DailyCollections from "./DailyCollections";
import FiveDayCollections from "./FiveDayCollections";
import CollectionsSummary from "./CollectionsSummary";
import Disbursements from "./Disbursements";
import FeesCollected from "./FeesCollected";
import PortfolioValue from "./PortfolioValue";
import Expenses from "./Expenses";
import ClientAquisition from "./ClientAquisition";
import DailyDisbursements from "./DailyDisbursements";
import Ratings from "./Ratings";

const Reports = () => {
  const collections = useSelector(
    (state) => state.collection.collections.value
  );
  const [filteredAndSortedCollections, setFilteredAndSortedCollections] =
    useState(collections);
  const [currentReport, setCurrentReport] = useState("");

  useEffect(() => {
    if (currentReport === "Collections") {
      if (filteredAndSortedCollections.length === 0) {
        setFilteredAndSortedCollections(collections);
      }
    }
  }, [collections, currentReport, filteredAndSortedCollections]);

  return (
    <>
      <Navbar />
      <div className="fc fdv aic reportsMainDiv w3-responsive">
        <h1>Reports</h1>
        <div className="fc fdv jc aic">
          <span id="generateAReportSpan">Generate a Report</span>
          <select
            className="w3-select reportSelect"
            name="report"
            onChange={(e) => {
              setCurrentReport(e.target.value);
            }}
            defaultValue={""}
          >
            <option value="" disabled>
              Choose your option
            </option>
            <option value="Daily_Collections">Daily Collections</option>
            <option value="Collections">5-day Collections</option>
            <option value="Collections_Summary">Collections Summary</option>
            <option value="Daily_Disbursements">Daily Loan Disbursements</option>
            <option value="Disbursements">Loans Disbursed</option>
            <option value="Fees">Fees Collected</option>
            <option value="Loan_Value">Loan Portfolio Value</option>
            <option value="outstanding_balance">Outstanding Balance</option>
            <option value="client_acquisitions">Client Acquisition</option>
            <option value="expenses">Expenses</option>
            <option value="ratings">Ratings</option>
            <option value="profit">Profit</option>
            <option value="shares">Shares</option>
          </select>
        </div>
        {currentReport === "Daily_Collections" && <DailyCollections />}
        {currentReport === "Collections" && <FiveDayCollections />}
        {currentReport === "Collections_Summary" && (
          <CollectionsSummary currentReport={currentReport} />
        )}
        {currentReport === "Disbursements" && (
          <Disbursements currentReport={currentReport} />
        )}
        {
          // Fees
          currentReport === "Fees" && (
            <FeesCollected currentReport={currentReport} />
          )
        }
        {
          // loan value
          currentReport === "Loan_Value" && (
            <PortfolioValue currentReport={currentReport} />
          )
        }
        {currentReport === "expenses" && <Expenses />}
        {currentReport === "client_acquisitions" && <ClientAquisition />}
        {currentReport === "Daily_Disbursements" && <DailyDisbursements />}
        {currentReport === "ratings" && <Ratings />}
      </div>
    </>
  );
};

export default Reports;
