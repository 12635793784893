import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { parseBoolean } from "../../utils/utils";
import { setApplicationMode } from "../../reduxSlices/slices/applicationModeSlice";
import { setCustomer } from "../../reduxSlices/slices/customerSlice";

const HomeCard = () => {
  const is_authenticated = useSelector((state) =>
    parseBoolean(state.isAuthenticated.auth.value)
  );
  const security_level = useSelector(
    (state) => state.isAuthenticated.security_level.value
  );
  const user = useSelector((state) => state.isAuthenticated.auth_user.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className="fc fdv aic homeWelcomeCard"
      style={{ border: "1px solid gray", borderRadius: "10px" }}
    >
      <header className="fc jc aic homeWelcomeHeader">
        <span>Processing Loan Applications Made Easy</span>
      </header>
      <div className="fc fdv aic jsb homeWelcomeBody">
        <div className="homeWelcomeParagraphDiv">
          <p className="fc jc aic homeWelcomeParagraph">
            {is_authenticated && parseInt(security_level) === 1 ? (
              <span className="w3-text-green w3-padding">Agent Access</span>
            ) : (
              <>
                {is_authenticated && parseInt(security_level) >= 2 ? (
                  <span className="w3-text-green w3-padding">Executive Access</span>
                ) : (
                  ""
                )}
              </>
            )}
          </p>
          <p>
            Welcome to tamicroloans executive and agents app.{" "}
            {is_authenticated ? (
              <span>
                You are currently logged in as{" "}
                <span className="w3-yellow">{user}</span>{" "}
              </span>
            ) : (
              <span>
                You need to log in to access the features of this app!
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="fc jc aic homeWelcomeFooter">
        <button
          className="w3-button w3-white w3-hover-none w3-hover-text-grey fc jc aic"
          id="homeFooterClientsTableButton"
          onClick={() => {
            if (!is_authenticated) {
              navigate("/login");
            } else {
              dispatch(setApplicationMode({ mode: "new_applicant" }));
              dispatch(
                setCustomer({
                  last_name: "",
                  first_name: "",
                  ghana_card_id: "",
                  phone: "",
                  loan_id: "",
                  loan_date: "",
                  loan_amount_ghs: "",
                  loan_duration_wks: "",
                  registration_fee_ghs: "",
                  insurance_fee_ghs: "",
                  loan_interest_rate: "",
                  amount_remaining_ghs: "",
                })
              );
              navigate("/customerInfo");
            }
          }}
        >
          <span className="material-symbols-sharp">add</span>
          <span>New Loan</span>
        </button>
        {parseInt(security_level) >= 2 && (
          <button
            className="w3-button w3-white w3-hover-none w3-hover-text-grey fc jc aic"
            id="homeFooterNewClientButton"
            onClick={() => {
              if (!is_authenticated) {
                navigate("/login");
              } else {
                dispatch(setApplicationMode("existing_application"));
                navigate("/clients");
              }
            }}
          >
            <span className="material-symbols-sharp">view_list</span>
            <span>Client List</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default HomeCard;
