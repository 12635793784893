import { createSlice } from "@reduxjs/toolkit";

export const investmentAccountSlice = createSlice({
  name: "investmentAccount",
  initialState: {
    investmentAccount: {
      value: {
        ghana_card_id: "", // Use Ghana Card Identification Number
        last_name: "",
        first_name: "",
        account_number: "", // Use Account Number
        amount: 0,
        period: 1, // 1, 6, 12, 18, 24
        rate: 5, // 5, 12, 30, 100, 200
        openingDate: "",
        maturityDate: "",
        status: "active", // active, matured, closed
      },
    },
    investmentAccounts: { value: [] },
  },
  reducers: {
    setCurrentInvestment: (state, investmentAccount = {}) => {
      if (state.investmentAccount.value !== investmentAccount.payload) {
        state.investmentAccount.value = investmentAccount.payload;
      }
    },
    setInvestmentAccounts: (state, investmentAccounts = []) => {
      if (
        JSON.stringify(state.investmentAccounts.value) !==
        JSON.stringify(investmentAccounts.payload)
      ) {
        state.investmentAccounts.value = investmentAccounts.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentInvestment, setInvestmentAccounts } =
  investmentAccountSlice.actions;

export default investmentAccountSlice.reducer;
